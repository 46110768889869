import React from 'react';
// import './ConfirmationModal.css';

const ConfirmationModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-container">

            <div className="modal-content">
                <h1>Confirmation</h1>
                <p>Your details have been shared with the Buyer Agent. They will contact you using the provided information.</p>
                <button onClick={onClose} className="login-button">Okay</button>
            </div>
        </div>
    );
};

export default ConfirmationModal;