import React, { useState, useEffect } from 'react';
import axios from 'axios';
import StepTabs from './components/RealEstateSignup';
import BuyerAgentCard from '../src/components/BuyerAgentCard';
import Header from './components/Header';
import Footer from './components/Footer';

const BuyerAgentsList = ({ }) => {
    const [buyerAgents, setBuyerAgents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userDetails, setUserDetails] = useState(null)



    useEffect(() => {
        let userDetailFull = JSON.parse(localStorage.getItem("userDetails"));
        console.log(userDetailFull, "userDetailFull");

        if (userDetailFull) {
            setUserDetails(userDetailFull)
            axios
                .get('https://api.agentmatchr.com/api/allBuyerAgents')
                .then((response) => {
                    console.log(response);
                    setBuyerAgents(response.data.data);
                    // setLoading(false);
                })
                .catch((err) => {
                    // setError('Failed to fetch buyer agents');
                    // setLoading(false);
                });
        }

    }, []);




    //   useEffect(() => {
    //     if (userDetails) {
    //       // Fetch buyer agents data if user details are provided
    //       axios
    //         .get('https://api.agentmatchr.com/api/allBuyerAgents')
    //         .then((response) => {
    //           setBuyerAgents(response.data);
    //           setLoading(false);
    //         })
    //         .catch((err) => {
    //           setError('Failed to fetch buyer agents');
    //           setLoading(false);
    //         });
    //     } else {
    //       setLoading(false);
    //     }
    //   }, [userDetails]);

    if (!userDetails) {
        return <StepTabs />;
    }

    //   if (loading) {
    //     return <div>Loading...</div>;
    //   }

    //   if (error) {
    //     return <div>{error}</div>;
    //   }

    return (
        <div>

            <Header height={0.01} />
            <div className='list-buyer'>

                <h2 >List of Buyer Agents</h2>
                <div className='row'>
                    <div className='col-lg-3 col-md-12 col-sm-12 buyer-search-result'>
                        {/* <h3>Your Search</h3> */}
                        <h5>Property Details</h5>
                        <h6>Property Type</h6>
                        <p>{userDetails.propertyType}</p>
                        <h6>Number of Rooms</h6>
                        <p>{userDetails.bedroomCount}</p>
                        <h6>Budget</h6>
                        <p>{userDetails.weeklyOrSaleValue}</p>
                        <br></br>
                        <h5>Property Location</h5>
                        <h6>Property Address</h6>
                        <p>{userDetails.location}</p>
                        <br></br>
                        <h5>Your Preferences</h5>
                        <h6>When are you planning?</h6>
                        <p>{userDetails.selectedOption}</p>
                        <h6>Your Purpose?</h6>
                        <p>{userDetails.selectedPurpose}</p>
                        <h6>Way to communicate?</h6>
                        <p>{userDetails.selectedUserCommunication}</p>
                        <h6>Mortgage assistance?</h6>
                        <p>{userDetails.isLegalReady? "Yes": "No"}</p>
                        <h6>Negotiation and Closing?</h6>
                        <p>{userDetails.isAssistance ? "Yes": "No"}</p>
                        
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 '>
                        <div style={{ display: 'flex', flexDirection:"column" }}>
                            {buyerAgents.length > 0 ? (
                                buyerAgents.map((agent) => (
                                    <BuyerAgentCard
                                        key={agent._id}
                                        agent={agent}
                                    />
                                ))
                            ) : (
                                <p>No buyer agents available.</p>
                            )}
                        </div>
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    );
};

export default BuyerAgentsList;
