import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          {/* About Section */}
          <div className="col-lg-4">
            <div className="about">
              <div className="logo">
                FindMyAgent
              </div>
              {/* <p>
                If you find this{' '}
                <a href="https://templatemo.com/tm-564-plot-listing" target="_parent" rel="noopener noreferrer">
                  template useful
                </a>
                , please{' '}
                <a href="https://www.paypal.me/templatemo" target="_blank" rel="noopener noreferrer">
                  support us
                </a>{' '}
                via PayPal.
              </p> */}
            </div>
          </div>

          {/* Helpful Links */}
          <div className="col-lg-4">
            <div className="helpful-links">
              <h4>Helpful Links</h4>
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <ul>
                    <li><a href="#">Categories</a></li>
                    <li><a href="#">Reviews</a></li>
                    <li><a href="#">Listing</a></li>
                    <li><a href="#">Contact Us</a></li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li><a href="#">About Us</a></li>
                    <li><a href="#">Awards</a></li>
                    <li><a href="#">Useful Sites</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Us */}
          <div className="col-lg-4">
            <div className="contact-us">
              <h4>Contact Us</h4>
              <p>27th Street of New Town, Digital Villa</p>
              <div className="row">
                <div className="col-lg-6">
                  <a href="tel:0100200340">010-020-0340</a>
                </div>
                <div className="col-lg-6">
                  <a href="tel:0900800760">090-080-0760</a>
                </div>
              </div>
            </div>
          </div>

          {/* Sub Footer */}
          <div className="col-lg-12">
            <div className="sub-footer">
              <p>
                Copyright © 2024 FindMyAgent., Ltd. All Rights Reserved.
                <br />
                
                <a href="#" title="Find My Agent">
                  FindMyAgent
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
