import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { FaExclamationCircle } from "react-icons/fa";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(null);
    const navigate = useNavigate();

    const showToast = (message) => {
        toast.error(message, {
            icon: <FaExclamationCircle color="#f3f3f3" />,
            position: "top-right",
            autoClose: 3000, // Automatically close after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                fontSize: "18px",
                backgroundColor: "#FD0A51", // Custom background color
                color: "#ffffff", // Custom text color
                border: "1px solid #fff", // Border color
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Custom box-shadow
                borderRadius: "8px",
            },
        });
        navigate("/agentPortal");
    };

    const handleLogin = async () => {
        const loginData = {
            email: email,
            password: password
        };
    
    
        try {
            const response = await axios.post('https://api.agentmatchr.com/api/buyerAgent/login', loginData);
    
            if (response.data.code === 1) {
                
                // setToken(response.data.data.token); // Save the token if needed
                await localStorage.setItem('authToken', response.data.data.token);
                showToast('Login successful');
                
            } else {
                showToast('Login failed: ' + response.data.message);
            }
        } catch (error) {
            console.error(error);
            showToast('Error: ' + error.message);
        }
    };
    

    return (
        <div className="modal-container">
            <ToastContainer />
            <div className="modal-content">
                <div className="modal-header">
                    {/* <img src="/path/to/logo.png" alt="Logo" className="modal-logo" /> */}
                    <h1>Log in to Agent Portal</h1>
                </div>
                <p>Enter your email address and password to log in.</p>
                <form>
                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="address-input"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="address-input"
                        />
                    </div>
                    <div className="form-links">
                        <a href="#">Forgot password</a>
                        <a href="#">Don't have a password yet</a>
                    </div>
                    <button type="button" className="login-button" onClick={handleLogin}>
                        Login
                    </button>
                </form>
                <div className="modal-footer">
                    <p>Don't have an account?</p>
                    <p>
                        If you're a Real Estate Agent and would like to start receiving leads now,{' '}
                        <a href="#">register here</a> to create your own agent profile, or visit our{' '}
                        <a href="#">How It Works</a> page.
                    </p>
                </div>
            </div>
            <div className="modal-overlay" onClick={onClose}></div>
        </div>
    );
};

export default LoginModal;
