import React, { useState, useEffect } from 'react';
import Footer from './components/Footer';
import './ProfileDetails.css';
import { useLocation } from 'react-router-dom';
import { FaUserCircle, FaCheckCircle, FaCalendarAlt, FaDog, FaMale, FaBusinessTime, FaVideo, FaAlignJustify } from 'react-icons/fa';
import ConfirmationModal from './components/ConfirmationModal';
import axios from 'axios';

const ProfileDetails = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const [userDetails, setUserDetails] = useState(null)
    const { buyerAgent } = location.state || {};
    const [responseMessage, setResponseMessage] = useState('');



    useEffect(() => {
        let userDetailFull = JSON.parse(localStorage.getItem("userDetails"));
        // console.log(userDetailFull, "userDetailFull");

        if (userDetailFull) {
            setUserDetails(userDetailFull)
        }
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > window.innerHeight * 0.01) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const clickedContactAgent = async () => {
        try {
            let data = {
                bedroomCount: userDetails.bedroomCount,
                emailAddress: userDetails.emailAdress,
                isAssistance: userDetails.isAssistance,
                isLegalReady: userDetails.isLegalReady,
                location: userDetails.location,
                phoneNumber: userDetails.phoneNumber,
                propertyType: userDetails.propertyType,
                selectedOption: userDetails.selectedOption,
                selectedPurpose: userDetails.selectedPurpose,
                selectedUserCommunication: userDetails.selectedUserCommunication,
                weeklyOrSaleValue: userDetails.weeklyOrSaleValue,
                buyerAgentId: buyerAgent._id,
                isAccepted: false
            }
            // console.log(data, "userDetails");
            // console.log(buyerAgent,"buyerAgent");

            const response = await axios.post('https://api.agentmatchr.com/api/userRequest', data);

            if (response.status === 200) {
                setResponseMessage('Request submitted successfully!');
                setIsModalOpen(true);
            } else {
                setResponseMessage('Failed to submit request.');
                setIsModalOpen(true);
            }
        } catch (error) {
            console.error("Error submitting request:", error);
            setResponseMessage('An error occurred while submitting your request.');
        }
    }

    if (!buyerAgent) {
        return <p>No agent data available</p>;
    } else {
        console.log(buyerAgent);
    }

    return (
        <div>
            <header className={`header-area ${isScrolled ? 'header-scrolled' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="main-nav">
                                <a href="/" className="logo">FindMyAgent</a>
                                <ul className="nav">

                                    <li>
                                        <div className="main-white-button-header">
                                            <a onClick={clickedContactAgent}> Contact This Agent</a>
                                        </div>
                                    </li>
                                </ul>
                                <div className="menu-trigger " onClick={toggleMenu}>
                                    <FaAlignJustify className="menu-trigger" />
                                    {isMenuOpen && (
                                        <ul className="nav mobile-menu"> {/* Mobile only */}
                                            <li>
                                                <a href="/becomeAgent">Become an Agent</a>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <div className="profile-details">
                <div className="profile-header">
                    {/* <div className="profile-cover"></div> */}
                    <div className="profile-info">
                        {buyerAgent.imageUrl ? (
                            <img
                                src={buyerAgent.imageUrl}
                                alt={`${buyerAgent.firstName} ${buyerAgent.lastName}'s profile`}
                                className="profile-image"
                            />
                        ) : (
                            <FaUserCircle className="profile-image" />
                        )}
                        <div className="profile-text">
                            <h1>{buyerAgent.firstName} {buyerAgent.lastName}</h1>
                            <p className="location">{buyerAgent.role[0]} at {buyerAgent.agencyName}</p>
                            <p className="location">{buyerAgent.officeAddress}</p>
                            <p className="rating"><FaCheckCircle className="check-icon" />  5.0 (14 ratings)</p>
                        </div>
                    </div>
                </div>

                <div className="about-section">
                    <h2>Find Your Dream Home</h2>
                    {/* <div className="about-box">
                        <h3>About {buyerAgent.firstName}</h3>
                        <p>{buyerAgent.proposals[0].aboutYou}</p>
                    </div> */}
                </div>
                <div className='upper-section'>
                    <div className='upper-section-about'>
                        <div className="about-section">
                            {/* <h2>Find Your Dream Home</h2> */}
                            <div className="about-box">
                                <h3>About {buyerAgent.firstName}</h3>
                                <p>{buyerAgent.proposals[0].aboutYou}</p>
                            </div>
                        </div>
                        <div className="about-section">
                            {/* <h2>Find Your Dream Home</h2> */}
                            <div className="about-box">
                                <h3>About {buyerAgent.agencyName}</h3>
                                <p>{buyerAgent.proposals[0].aboutAgency}</p>
                            </div>
                        </div>

                        <div className="experience-section">
                            <h3>Services Offered</h3>
                            <div className="experience-list">
                                {buyerAgent.proposals[0].services.map((service, index) => (
                                    <div key={index} className="service-item">{service}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="video-section">
                        <h3>Introduction Video</h3>
                        <iframe
                            // width="400"
                            height="250"
                            src="https://www.youtube.com/embed/VNLtxpHGNT8?si=iQcTiJlDIgbDcUVV"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>

                <div className="experience-section">
                    <h3>Our Specializations</h3>
                    <div className="experience-list">
                        {buyerAgent.proposals[0].specializations.map((service, index) => (
                            <div key={index} className="service-item">{service}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="pets-accepted">
                    <h3>Additional Details</h3>
                    <div className="pet-types">
                        <div className="pet-type"><FaMale className='icon-additional' /> Team Count <br /><span>{buyerAgent.proposals[0].salesTeamSize}</span></div>
                        <div className="pet-type"><FaBusinessTime className='icon-additional' /> Buyer Agreement Period <br /><span>{buyerAgent.proposals[0].saleAuthority}</span></div>
                        <div className="pet-type"><FaVideo className='icon-additional' /> Video Call Option <br /><span>{buyerAgent.proposals[0].videoCallOption}</span></div>
                        {/* Add more pet types as needed */}
                    </div>
                </div>

                <div className="experience-section">
                    <h3>Postcodes Which We Serve</h3>
                    <div className="experience-list">
                        {buyerAgent.proposals[0].postcodes.map((service, index) => (
                            service ? <div key={index} className="service-item">{service}</div> : null
                        ))}
                    </div>
                </div>
                <div className="about-section mt-5">
                    {/* <h2>Find Your Dream Home</h2> */}
                    <div className="about-box mt-5">
                        <h3>Review's About {buyerAgent.agencyName}</h3>
                        <p>No Reviews yet.</p>
                    </div>
                </div>
                {isModalOpen && <ConfirmationModal isOpen={isModalOpen} onClose={handleCloseModal} />}
            </div>
            <Footer />
        </div>

    );
};

export default ProfileDetails;
