import React, { useState } from 'react';
import { FaArrowCircleLeft,FaExclamationCircle} from "react-icons/fa";
import Footer from './components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const showToast = (message) => {
    toast.error(message, {
        icon: <FaExclamationCircle color="#f3f3f3" />,
        position: "top-right",
        autoClose: 3000, // Automatically close after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            fontSize: "18px",
            backgroundColor: "#FD0A51", // Custom background color
            color: "#ffffff", // Custom text color
            border: "1px solid #fff", // Border color
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Custom box-shadow
            borderRadius: "8px",
        },
    });
};



// Step 1: About You
const AboutYou = ({ onNext, onBack, formData, setFormData }) => {
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleNext = () => {
        const { firstName, lastName, phone,email } = formData;
    
        // Check if any fields are missing
        if (!firstName || !lastName || !phone || !email) {
          // Show a toast message if any fields are empty
          showToast('Please fill in all the fields before proceeding.')
        } else {
          // Proceed to the next step
          onNext();
        }
      };

    return (
        <div className="about-you ">
            {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}

            <h1 >About you</h1>
            <p >Let's begin with your basic details.</p>
            <div className='row'>
                <form className='col-lg-12 address-input-group'>
                    <div className="form-group-input">
                        <label htmlFor="userName" className="address-label">First Name</label>
                        <input
                            type="text"
                            id="userName"
                            name="firstName"
                            className="form-control"
                            placeholder="First name"
                            value={formData.firstName || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group-input">
                        <label htmlFor="lastNameId" className="address-label">Last Name</label>
                        <input
                            type="text"
                            id="lastNameId"
                            name="lastName"
                            className="form-control"
                            placeholder="Last name"
                            value={formData.lastName || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group-input">
                        <label htmlFor="phone" className="address-label">Phone number</label>
                        <input
                            type="text"
                            id='phone'
                            name="phone"
                            className="form-control"
                            placeholder="Phone number"
                            value={formData.phone || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group-input">
                        <label htmlFor="email" className="address-label">Email address</label>
                        <input
                            type="text"
                            name="email"
                            id='email'
                            className="form-control"
                            placeholder="Email address"
                            value={formData.email || ''}
                            onChange={handleChange}
                        />
                    </div>
                </form>
                <div>
                    <button className='btn-step ' onClick={onBack}>Back</button>
                    <button className='btn-step ' onClick={handleNext}>Next</button>
                </div>

            </div>

        </div>
    );
};

// Step 2: About Your Agency
const AboutAgency = ({ onNext, onBack, formData, setFormData }) => {
    const handleChange = (e) => {

        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle next button click with validation
  const handleNext = () => {
    const { officeAddress, agencyName, branch } = formData;

    // Check if any fields are missing
    if (!officeAddress || !agencyName || !branch) {
      // Show a toast message if any fields are empty
      showToast('Please fill in all the fields before proceeding.')
    } else {
      // Proceed to the next step
      onNext();
    }
  };

    return (
        <div className="about-you">
            {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}
            <h1>About your agency</h1>
            <p>Great! Now we just need a few details about your agency.</p>
            <div className='row'>
                <form className=' col-lg-12 address-input-group'>
                    <div className="form-group-input">
                        <label htmlFor="officeAddressId" className="address-label">Your Office Address</label>

                        <input
                            id='officeAddressId'
                            type="text"
                            name="officeAddress"
                            className='form-control'
                            placeholder="Start typing an address"
                            value={formData.officeAddress || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group-input">
                        <label htmlFor="officeAddressId" className="address-label">Agency Name</label>

                        <input
                            id='agencyNameId'
                            type="text"
                            name="agencyName"
                            className='form-control'
                            placeholder="Type agency name"
                            value={formData.agencyName || ''}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group-input">
                        <label htmlFor="officeAddressId" className="address-label">Branch</label>

                        <input
                            id='branchId'
                            type="text"
                            name="branch"
                            className='form-control'
                            placeholder='St Thomas'
                            value={formData.branch || ''}
                            onChange={handleChange}
                        />
                    </div>

                </form>
                <div>
                    <button className='btn-step ' onClick={onBack}>Back</button>
                    <button className='btn-step ' onClick={handleNext}>Next</button>
                </div>
            </div>


        </div>
    );
};

const CongratsPage = ({ onNext, onBack, formData, setFormData }) => {
    // console.log(formData);
    console.log(localStorage.getItem("authToken"));
    const handleSelectRole = () => {
        // setFormData({ ...formData, role });
        onNext();
    };

    return (
        <section className="registration">
            {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}

            <h1>Congrats! Your account has been created.</h1>
            <p>Begin with your proflie to find your potential clients.</p>
            {/* <p>{formData}</p> */}
            <div className="registration-button">
                <button className='btn-step' onClick={() => handleSelectRole()}>Start</button>
            </div>
        </section>
    );
};

const OurFees = ({ onNext, onBack, formData, setFormData }) => {
    const handleFeeSelection = (feeType) => {
        setFormData({ ...formData, feeType });
        onNext();
    };

    return (
        <div className="about-you ourfee ">
            {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}
            <div className="content">
                <h1>Our Fees</h1>
                <p>
                    Our pay-on-success model means your agency will only ever pay a service fee if you successfully transact on a property we refer you to.
                </p>

                <div className="fees-section row">
                    <div className="fee-info col-lg-4 col-sm-12 col-md-12">
                        <p><strong>Even if you already knew the client, the fee is still applicable when:</strong></p>
                        <span>No sale authority, letting authority or property management agreement in relation to a property owned by the client existed at the time the lead was provided.</span>
                        <span>No home visit appraisal had been performed within 90 days prior to detail exchange.</span>
                    </div>

                    <div className="fee-highlight col-lg-4 col-sm-12 col-md-12">
                        <h3>Sale fee</h3>
                        <div className="sale-fee">
                            <span className="percentage">0.395%</span>
                            <p>of final sale price capped at $4,750 (+ GST)</p>
                        </div>
                    </div>

                    <div className="fee-info col-lg-4 col-sm-12 col-md-12">
                        <p><strong>The fee isn't applicable if you can provide written evidence of:</strong></p>
                        <span>An existing sale authority, letting authority or property management agreement in relation to a property owned by the client at the time of receiving the referral.</span>
                        <span>A home visit appraisal performed within 90 days prior to receiving the referral.</span>
                    </div>
                </div>

                <div className="rental-fee">
                    <p>Rental fee <strong>1.5 Week's Rent</strong> + GST</p>
                </div>

                <div>
                    <button className='btn-step ' onClick={onBack}>Back</button>
                    <button className='btn-step ' onClick={onNext}>Next</button>
                </div>


            </div>
        </div>
    );
};
const RoleSelection = ({ onNext, onBack, formData, setFormData }) => {
    const handleSelectRole = (role) => {
        setFormData({ ...formData, role });
        onNext();
    };

    return (
        <div className="registration role-div">
            <FaArrowCircleLeft className='back-icon' onClick={onBack} />
            <h1 className="role">What Is your role?</h1>
            <div className="role-button row">
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'ADMINISTRATION MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('ADMINISTRATION MANAGER')}
        >
          ADMINISTRATION MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'ASSISTANT PROPERTY MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('ASSISTANT PROPERTY MANAGER')}
        >
          ASSISTANT PROPERTY MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'ASSISTANT TO THE PRINCIPAL' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('ASSISTANT TO THE PRINCIPAL')}
        >
          ASSISTANT TO THE PRINCIPAL
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'ASSOCIATE DIRECTOR' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('ASSOCIATE DIRECTOR')}
        >
          ASSOCIATE DIRECTOR
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'BUSINESS DEVELOPMENT MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('BUSINESS DEVELOPMENT MANAGER')}
        >
          BUSINESS DEVELOPMENT MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'DIRECTOR' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('DIRECTOR')}
        >
          DIRECTOR
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'INVESTOR SERVICES MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('INVESTOR SERVICES MANAGER')}
        >
          INVESTOR SERVICES MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'LICENSEE' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('LICENSEE')}
        >
          LICENSEE
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'LICENSED ESTATE AGENT' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('LICENSED ESTATE AGENT')}
        >
          LICENSED ESTATE AGENT
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'MARKETING COORDINATOR' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('MARKETING COORDINATOR')}
        >
          MARKETING COORDINATOR
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'OFFICE MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('OFFICE MANAGER')}
        >
          OFFICE MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'PRINCIPAL' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('PRINCIPAL')}
        >
          PRINCIPAL
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'PROPERTY MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('PROPERTY MANAGER')}
        >
          PROPERTY MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'RECEPTIONIST' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('RECEPTIONIST')}
        >
          RECEPTIONIST
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'RURAL LIFESTYLE SPECIALIST' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('RURAL LIFESTYLE SPECIALIST')}
        >
          RURAL LIFESTYLE SPECIALIST
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'SALES ASSISTANT' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('SALES ASSISTANT')}
        >
          SALES ASSISTANT
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'SALES CONSULTANT' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('SALES CONSULTANT')}
        >
          SALES CONSULTANT
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'SALES EXECUTIVE' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('SALES EXECUTIVE')}
        >
          SALES EXECUTIVE
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'SALES MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('SALES MANAGER')}
        >
          SALES MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'SENIOR PROPERTY MANAGER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('SENIOR PROPERTY MANAGER')}
        >
          SENIOR PROPERTY MANAGER
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'SENIOR SALES EXECUTIVE' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('SENIOR SALES EXECUTIVE')}
        >
          SENIOR SALES EXECUTIVE
        </button>
        <button
          className={`btn-step col-sm-12 col-md-12 col-lg-3 ${
            formData.role === 'OTHER' ? 'btn-step-selected' : ''
          }`}
          onClick={() => handleSelectRole('OTHER')}
        >
          OTHER
        </button>
      </div>
        </div>
    );
};


const WhichDescribesYourAgency = ({ onNext, onBack, formData, setFormData }) => {
    const handleAgencyDescription = (description) => {
        setFormData({ ...formData, agencyDescription: description });
        onNext();
    };

    return (
        <section className="registration">
            <FaArrowCircleLeft className='back-icon' onClick={onBack} />
            <h1>Which best describes your agency?</h1>
            <div className="registration-button">
                <button className={`btn-step col-lg-3 ${formData.agencyDescription === 'FRANCHISE' ? 'btn-step-selected' : ''}`} onClick={() => handleAgencyDescription('FRANCHISE')}>FRANCHISE</button>
                <button className={`btn-step col-lg-3 ${formData.agencyDescription === 'CORPORATE' ? 'btn-step-selected' : ''}`} onClick={() => handleAgencyDescription('CORPORATE')}>CORPORATE</button>
                <button className={`btn-step col-lg-3 ${formData.agencyDescription === 'INDEPENDENT' ? 'btn-step-selected' : ''}`} onClick={() => handleAgencyDescription('INDEPENDENT')}>INDEPENDENT</button>
            </div>
        </section>
    );
};

const DescribeYourUserRole = ({ onNext, onBack, formData, setFormData }) => {
    const handleSelectRole = (description) => {
        setFormData({ ...formData, agencyDescription: description });
        onNext();
    };

    return (
        <section className="registration">
            <FaArrowCircleLeft className='back-icon' onClick={onBack} />
            <h1>Which best describes you?</h1>
            <div className="registration-button">
                <button className={`btn-step col-lg-3 ${formData.agencyDescription === 'PRINCIPAL/DIRECTOR' ? 'btn-step-selected' : ''}`} onClick={() => handleSelectRole('PRINCIPAL/DIRECTOR')}>PRINCIPAL/DIRECTOR</button>
                <button className={`btn-step col-lg-3 ${formData.agencyDescription === 'EMPLOYEE' ? 'btn-step-selected' : ''}`} onClick={() => handleSelectRole('EMPLOYEE')}>EMPLOYEE</button>
                <button className={`btn-step col-lg-3 ${formData.agencyDescription === 'CONTRACTOR' ? 'btn-step-selected' : ''}`} onClick={() => handleSelectRole('CONTRACTOR')}>CONTRACTOR</button>
            </div>
        </section>
    );
};


const AccountCreation = ({ onNext, onBack, formData, setFormData }) => {
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const SendCode = async () => {
      const requestData = {
          email: formData.email // Get email from formData
      };

      try {
          const response = await fetch('https://api.agentmatchr.com/api/sendCode', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestData)
          });

          const result = await response.json();

          if (result.code === 1) {
            onNext();
              showToast('Code sent to email successfully');
               // Call the onNext prop to proceed
          } else {
              showToast('Failed to send code: ' + result.message);
          }
      } catch (error) {
          showToast('Error: ' + error.message);
      }
  };

    const handleNext = () => {
        const { password, confirmPassword } = formData;
    
        // Check if any fields are missing
        if (!password || !confirmPassword ) {
          // Show a toast message if any fields are empty
          showToast('Please fill in all the fields before proceeding.')
        }else if(password!==confirmPassword){
            showToast('Password Mismatch.')
        }
         else  {
          // Proceed to the next step
          showToast("Sending Code to "+ formData.email)
          SendCode()
        }
      };

    return (
        <div className="about-you">
            <FaArrowCircleLeft className='back-icon' onClick={onBack} />
            <div className="content">
                <h1>Create your account login</h1>
                <form className='address-input-group '>
                    {/* Email Input */}
                    <div className="form-group-input">
                        <label htmlFor="emailAddressSign" className="address-label">Email</label>
                        <input
                            id='emailAddressSign'
                            type="email"
                            name="email"
                            placeholder="Your email address"
                            value={formData.email || ''}
                            onChange={handleChange}
                            className="form-control"
                            readOnly
                        />
                    </div>

                    {/* Password Input */}
                    <div className="form-group-input">
                        <label htmlFor="emailPasswordSign" className="address-label">Password</label>
                        <input
                            id='emailPasswordSign'
                            type="password"
                            name="password"
                            placeholder="Type password"
                            value={formData.password || ''}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>

                    {/* Confirm Password Input */}
                    <div className="form-group-input">
                        <label htmlFor="confirmPassword" className="address-label">Confirm Password</label>
                        <input
                            id="confirmPassword"
                            type="password"
                            name="confirmPassword"
                            placeholder="Re-type password"
                            value={formData.confirmPassword || ''}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                </form>

                <div>
                    <button className='btn-step ' onClick={onBack}>Back</button>
                    <button className='btn-step ' onClick={handleNext}>Next</button>
                </div>
            </div>
        </div>
    );
};

const CoverType = ({ onNext, onBack, formData,  setFormData }) => {

    const handleSelectCoverType = (coverType) => {
        setFormData({ ...formData, coverType });
    
        onNext();
    };

    return (
        <section className="registration">

            <h1>Do you cover sales, rentals, or both?</h1>
            <div className="registration-button">
                <button className={`btn-step col-lg-3 ${formData.coverType === 'SALES' ? 'btn-step-selected' : ''}`} onClick={() => handleSelectCoverType('SALES')}>SALES</button>
                <button className={`btn-step col-lg-3 ${formData.coverType === 'RENTALS' ? 'btn-step-selected' : ''}`} onClick={() => handleSelectCoverType('RENTALS')}>RENTALS</button>
                <button className={`btn-step col-lg-3 ${formData.coverType === 'BOTH' ? 'btn-step-selected' : ''}`} onClick={() => handleSelectCoverType('BOTH')}>BOTH</button>
            </div>
        </section>
    );
};

const EmailConfirmationStep = ({ onNext, onBack, formData, setFormData }) => {
    const [code, setCode] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    };

    const verifyCode = async () => {
      const requestData = {
          email: formData.email,
          otpCode: code
      };

      try {
          const response = await fetch('https://api.agentmatchr.com/api/verifyCode', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestData)
          });

          const result = await response.json();

          if (result.code === 1) {
              showToast('Code verified successfully');
              onNext(); // Move to the next step if code verification is successful
          } else {
              showToast('Verification failed: ' + result.message);
          }
      } catch (error) {
          showToast('Error: ' + error.message);
      }
  };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (code) {
            // Proceed to the next step or API submission logic
            verifyCode();
        } else {
            // Handle validation error (e.g., if no code is entered)
            showToast("Please enter the confirmation code.");
        }
    };

    return (
        <div className="about-you">
            <FaArrowCircleLeft className='back-icon' onClick={onBack} />
            <div className='content'>


                <h1>Create your account login</h1>
                <p>Please verify your email by providing the code we just emailed.</p>

                <form onSubmit={handleSubmit} className="address-input-group">
                    {/* Email Address Input */}
                    <div className="form-group-input">
                        <label htmlFor="emailAddress" className="address-label">Your Email Address</label>
                        <input
                            type="email"
                            id="emailAddress"
                            name="email"
                            className="form-control"
                            value={formData.email || ''}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>

                    {/* Confirmation Code Input */}
                    <div className="form-group-input">
                        <label htmlFor="confirmationCode" className="address-label">Enter Your Confirmation Code</label>
                        <input
                            type="text"
                            id="confirmationCode"
                            className="form-control"
                            placeholder="Enter code"
                            value={code}
                            onChange={handleCodeChange}
                        />
                    </div>

                    {/* Submit Button */}
                    {/* <button type="submit" className="btn-submit">Submit</button> */}
                    <div>
                        <button className='btn-step ' onClick={onBack}>Back</button>
                        <button type="submit" className='btn-step' >Submit</button>
                    </div>
                </form>

                <p className="resend-link">
                    <a href="#resend">Resend code via email</a>
                </p>
            </div>
        </div>
    );
};



const MultiStepForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isScrolled, setIsScrolled] = useState(false);
    const [formData, setFormData] = useState({}); // State to store all form data
    const navigate = useNavigate();

  
    const handleNext = () => {
        // if (currentStep < steps.length - 1) {

        // } else {
        //     handleSubmit();
        // }
        if(currentStep<8){
          if(currentStep===7){
            handleAccountSubmit()
          }
            setCurrentStep(currentStep + 1);
        }else{
            handleSubmit()
        }
        
        
        
        // if (currentStep === 6 && formData.videoCallOption === "NO") {
        //     setCurrentStep(currentStep + 1);
        // } else {
        //     setCurrentStep(currentStep + 1);
        // }

    };
    

    const handleBack = () => {
        if (currentStep > 0) {
            if (currentStep === 8 ) {
                setCurrentStep(currentStep - 1);
            } else {
                setCurrentStep(currentStep - 1);
            }
        }else{
            navigate("/becomeAgent")
        }
    };

    // Example of submitting form data
    const handleAccountSubmit = () => {

      console.log(formData);
      
      // Make API call here, using `formData`
      fetch('https://api.agentmatchr.com/api/buyerAgent/create', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
      })
          .then((response) => response.json())
          .then((data) => {
              console.log('Success:', data);
              localStorage.setItem('authToken', data.data.token);
              navigate('/agentProposal');
          })
          .catch((error) => {
              console.error('Error:', error);
              navigate('/agentProposal');
          });
  };
    // Example of submitting form data
    const handleSubmit = () => {
        navigate('/agentProposal');
        // Make API call here, using `formData`
        
    };

    const renderStepContent = () => {
        switch (currentStep) {
            // case 0:
            //     return <CoverType onNext={handleNext} onBack={handleBack} formData={formData}  setFormData={setFormData} />;
            case 0:
                return <AboutYou onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 1:
                return <DescribeYourUserRole onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 2:
                return <RoleSelection onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 3:
                return <AboutAgency onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 4:
                return <WhichDescribesYourAgency onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 5:
                return <OurFees onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 6:
                return <AccountCreation onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 7:
                return <EmailConfirmationStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 8:
                return <CongratsPage onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;




            default:
                return <AboutYou onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
        }
    };

    return (
      
        <div className="multi-step-form">
            <header className="header-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="main-nav">
                                <a href="/" className="logo">FindMyAgent</a>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <ToastContainer />
            {/* <ProgressBar currentStep={currentStep} /> */}
            {renderStepContent()}

            <Footer />
        </div>
    );
};

export default MultiStepForm;