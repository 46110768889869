import React, { useState, useEffect } from 'react';
import { FaPlus , FaAlignJustify } from 'react-icons/fa'; // Assuming you are using FontAwesome icons

const Header = ({height}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu toggle

  // console.log(height, "height");

  const [headerHeight, setHeaderHeight] = useState(window.innerHeight * height); // Example threshold set at 15% of screen height

    useEffect(() => {
        const handleResize = () => {
            setHeaderHeight(window.innerHeight * 0.15); // Update threshold based on current screen height
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const screenHeight = window.innerHeight;
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        // For mobile: change the scroll threshold for when the navbar becomes non-transparent
        if (scrollTop > screenHeight / 15) {  // Example: change after scrolling 1/3 of screen height
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }else{
        if (scrollTop > headerHeight) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`header-area ${isScrolled ? 'header-scrolled' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="main-nav">
              <a href="/" className="logo">FindMyAgent</a>
              <ul className="nav">
                <li>
                  <div className="main-white-button-header ">
                    <a href="/becomeAgent"> Become an Agent</a>
                  </div>
                </li>
              </ul>
              <div className="menu-trigger" onClick={toggleMenu}>
              <  FaAlignJustify className="menu-trigger" />
              {isMenuOpen && (
                <ul className="nav mobile-menu"> {/* Mobile only */}
                  <li>
                    <a href="/becomeAgent">Become an Agent</a>
                  </li>
                </ul>
              )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
