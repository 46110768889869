import React from 'react';
import '../BuyerCard.css';
import { FaUserCircle, FaStar, FaRedo, FaCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const BuyerAgentCard = ({ agent }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/buyer-agent-profile', { state: { buyerAgent: agent } });
  };

  return (
    <div className="buyer-agent-card card-buyer" onClick={handleButtonClick}>
      {/* Profile Image */}
      {agent.imageUrl ? (
        <img
          src={agent.imageUrl}
          alt={`${agent.firstName} ${agent.lastName}'s profile`}
          className="profile-image"
        />
      ) : (
        <FaUserCircle className="profile-icon" />
      )}

      {/* Agent Details */}
      <div className="agent-details">
        <h3 className="agent-name">{agent.firstName} {agent.lastName}</h3>
        <p className="slogan">{agent.role[0]} at {agent.agencyName }</p>
        <h1 className="about-agency">{agent.proposals[0].aboutAgency}</h1>
        <p className="location">{agent.officeAddress}</p>

        {/* Rating and Price */}
        <div className="rating-price">
          <FaStar className="star-icon" /> 
          <span className="rating">{agent.rating || '5.0'} ({agent.reviews || '1'})</span>
          {/* <span className="price">${agent.pricePerWalk || '51.00'} / walk</span> */}
        </div>

        {/* Additional Details */}
        {/* <div className="additional-info">
          <div className="info-item">
            <FaRedo className="icon" />
            <span>{agent.repeatGuests || '2'} Repeat guests</span>
          </div>
          <div className="info-item">
            <FaCalendarAlt className="icon" />
            <span>Accepts weekly bookings</span>
          </div>
        </div> */}

        {/* Enquiry Button */}
        {/* <button className="enquiry-button" onClick={handleButtonClick}>
          SEND ENQUIRY
        </button> */}
      </div>
    </div>
  );
};

export default BuyerAgentCard;
