import React, { useState,useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import oneImage from '../images/one.png';
import twoImage from '../images/two.png';
import threeImage from '../images/three.png';
import fourImage from '../images/four.png';
import finishImage from '../images/finish.png';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const StepTabs = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [propertyType, setPropertyType] = useState(null);
    const [bedroomCount, setBedroomCount] = useState(null);
    const [weeklyOrSaleValue, setWeeklyOrSaleValue] = useState(null);
    const [isRented, setIsRented] = useState(null);
    const [location, setLocation] = useState('');
    const [emailAdress, setEmailAdress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    // const [propertyType, setPropertyType] = useState(null); // Rent or Sell
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedPurpose, setSelectedPurpose] = useState(null);
    const [selectedUserCommunication, setSelectedUserCommunication] = useState(null);
    const [isAssistance, setIsAssistance] = useState(null);
    const [isLegalReady, setIsLegalReady] = useState(null);
    const [sendOtp, setSendOTP] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);

    const [code, setCode] = useState(null);

    const [userDetails, setUserDetails] = useState(null);
    const navigate = useNavigate();
    // const fetchSuggestions = async (query) => {
    //     if (query.length < 3) return; // Only fetch when query is longer than 3 characters
    //     const response = await fetch(
    //       `https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1&limit=5`
    //     );
    //     const data = await response.json();
    //     console.log(data);
    //     setSuggestions(data);
    //   };

    useEffect(() => {
        // Check if user details exist in localStorage
        const userDetailsData = localStorage.getItem("userDetails");

        if (localStorage.getItem("userDetails")) {
            // If userDetails exists, navigate to /find-buyer-agent
            setUserDetails(localStorage.getItem("userDetails"))

            navigate("/find-buyer-agent");
        } else {
            console.log("No user details found in localStorage");
            // Handle case where userDetails is not found (e.g., stay on the current page)
        }
    }, [navigate]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const verifyCode = async () => {
            const requestData = {
                email: emailAdress,
                otpCode: code
            };

            try {
                const response = await fetch('https://api.agentmatchr.com/api/verifyCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData)
                });

                const result = await response.json();

                if (result.code === 1) {
                    showToast('Code verified successfully');
                    // Move to the next step if code verification is successful
                    setVerifyEmail(true);

                } else {
                    showToast('Verification failed: ' + result.message);
                }
            } catch (error) {
                showToast('Error: ' + error.message);
            }
        };

        const SendCode = async () => {
            const requestData = {
                email: emailAdress // Get email from formData
            };

            try {
                const response = await fetch('https://api.agentmatchr.com/api/sendCode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData)
                });

                const result = await response.json();

                if (result.code === 1) {

                    showToast('Code sent to email successfully');
                    setSendOTP(true);
                    // Call the onNext prop to proceed
                } else {
                    showToast('Failed to send code: ' + result.message);
                }
            } catch (error) {
                showToast('Error: ' + error.message);
            }
        };



        if (!sendOtp) {
            await SendCode()
            return;
        }
        if (!verifyEmail) {
            await verifyCode()
            return;
        }
        if (sendOtp && verifyEmail) {
            const details = {
                propertyType,
                bedroomCount,
                weeklyOrSaleValue,
                location,
                emailAdress,
                phoneNumber,
                selectedOption,
                selectedPurpose,
                selectedUserCommunication,
                isAssistance,
                isLegalReady,
            };

            console.log({
                propertyType,
                bedroomCount,
                weeklyOrSaleValue,
                location,
                emailAdress,
                phoneNumber,
                selectedOption,
                selectedPurpose,
                selectedUserCommunication,
                isAssistance,
                isLegalReady,
            });
            localStorage.setItem("userDetails",JSON.stringify(details))
            setUserDetails(details)

            // navigate("/find-buyer-agent");
            window.location.href = "/find-buyer-agent";
        }

    };

    const tabs = [
        // { title: 'About You', image: oneImage },
        { title: 'About Property', image: oneImage },
        { title: 'Location', image: twoImage },
        { title: 'Your Needs', image: threeImage },
        { title: 'Finish', image: finishImage },
    ];

    // Handler for changing tabs
    const handleTabChange = (index) => {
        // if (index === 1 && isRented === null) {
        //     // alert('Please select either "Sell" or "Rent" before proceeding.');
        //     showToast('Please select either "Sell" or "Rent" before proceeding.')
        //     return;
        // }

        if (index === 1 && (!propertyType || !bedroomCount || !weeklyOrSaleValue)) {
            // alert('Please make sure all options are selected before proceeding.');
            showToast('Please make sure all options are selected before proceeding.')
            return;
        }

        if (index === 2 && location.trim() === '') {
            // alert('Please enter the location before proceeding.');
            showToast('Please enter the location before proceeding.')
            return;
        }
        if (index === 3 && (!selectedOption || !selectedUserCommunication || isLegalReady === null || selectedPurpose === null || isAssistance === null)) {
            showToast('Please make sure all options are selected before proceeding.')
            return;
        }

        setCurrentTab(index);
    };

    const showToast = (message) => {
        toast.error(message, {
            icon: <FaExclamationCircle color="#f3f3f3" />,
            position: "top-right",
            autoClose: 3000, // Automatically close after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                fontSize: "18px",
                backgroundColor: "#FD0A51", // Custom background color
                color: "#ffffff", // Custom text color
                border: "1px solid #fff", // Border color
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Custom box-shadow
                borderRadius: "8px",
            },
        });
    };

    const rentOptions = [
        'Less than $200', '$200 to $300', '$300 to $400', '$400 to $500',
        '$500 to $700', '$700 to $1000', '$1000 to $1500', '$1500+'
    ];

    const sellOptions = [
        'Less than $200k', '$200k to $400k', '$400k to $600k', '$600k to $800k',
        '$800k to $1m', '$1m to $1.5m', '$1.5m to $2m', '$2m+'
    ];

    // const onClickRentButton = () => {
    //     setIsRented(true)
    //     setCurrentTab(1)
    //     setWeeklyOrSaleValue(null);
    //     setPropertyType(null)
    //     setBedroomCount(null)
    //     setLocation('')
    //     setSelectedOption(null)
    //     setIsOwner(null)
    //     setIsAgentSpoken(null)
    //     setIsTenanted(null)
    //     setSelectedUserRelation(null)
    //     setEmailAdress("")
    //     setPhoneNumber("")
    // }

    // const onClickSellButton = () => {
    //     setIsRented(false)
    //     setCurrentTab(1)
    //     setWeeklyOrSaleValue(null);
    //     setPropertyType(null)
    //     setBedroomCount(null)
    //     setLocation('')
    //     setSelectedOption(null)
    //     setIsOwner(null)
    //     setIsAgentSpoken(null)
    //     setIsTenanted(null)
    //     setSelectedUserRelation(null)
    //     setEmailAdress("")
    //     setPhoneNumber("")
    // }
    // Handle input changes and trigger suggestion fetching
    const handleInputChange = (e) => {
        const value = e.target.value;
        setLocation(value);
        // fetchSuggestions(value);
    };
    const handlePhoneChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
        // fetchSuggestions(value);
    };
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmailAdress(value);
        // fetchSuggestions(value);
    };
    const handleCodeChange = (e) => {
        const value = e.target.value;
        setCode(value);
        // fetchSuggestions(value);
    };

    // Handle suggestion click
    const handleSuggestionClick = (suggestion) => {
        const formattedAddress = suggestion.display_name;
        setLocation(formattedAddress);
        // setSuggestions([]); // Clear suggestions after selection
    };

    const isContinueDisabled = !propertyType || !bedroomCount || !weeklyOrSaleValue;
    const isContinueLocatioDisabled = location === "";
    const isYourNeedsProvided = !selectedOption || !selectedPurpose || !selectedUserCommunication || (isLegalReady === null) || (isAssistance === null);
    const isContactProvided = !emailAdress || !phoneNumber;

    const renderTabContent = () => {
        switch (currentTab) {
            // case 0:
            //     return (
            //         <div className="container-steps">
            //             <h1>Are you looking to sell or rent your property?</h1>
            //             <div className="btn-group">
            //                 <div className={` btn-step ${isRented === false ? 'btn-step-selected' : ''}`} onClick={onClickSellButton}>Sell</div>
            //                 <div className={` btn-step ${isRented === true ? 'btn-step-selected' : ''}`} onClick={onClickRentButton}>Rent</div>
            //             </div>
            //             <div className="timer">
            //                 <img src={require("../images/fast.png")} alt="Clock Icon" />
            //                 <p>It takes only 2 Minutes.</p>
            //             </div>
            //             <div className="terms">
            //                 By continuing, I agree to FindMyAgent's <a href="#">Terms & Conditions</a> and <a href="#">Privacy Policy</a>.
            //             </div>

            //         </div>
            //     );
            case 0:
                return (
                    <div className="section property-container" >
                        <div>
                            <h3>What type of property are you looking to buy?</h3>
                            <div className="btn-group row">
                                {['House', 'Unit', 'Land', 'Apartment', 'Townhouse', 'Villa'].map((type, index) => (
                                    <button
                                        key={index}
                                        className={`btn-step col-lg-2 mb-1 ${propertyType === type ? 'btn-step-selected' : ''}`}
                                        onClick={() => setPropertyType(type)}
                                    >
                                        {type}
                                    </button>
                                ))}
                            </div>

                            <h3>How many bedrooms are you looking for?</h3>
                            <div className="btn-group row">
                                {['1', '2', '3', '4+'].map((bedrooms, index) => (
                                    <button
                                        key={index}
                                        className={`btn-step col-lg-2 m-1 col-sm-12 ${bedroomCount === bedrooms ? 'btn-step-selected' : ''}`}
                                        onClick={() => setBedroomCount(bedrooms)}
                                    >
                                        {bedrooms}
                                    </button>
                                ))}
                            </div>

                            <h3>What's your ideal price range for the property?</h3>
                            <div className="btn-group row">
                                {sellOptions.map((value, index) => (
                                    <button
                                        key={index}
                                        className={`btn-step col-lg-3  ${weeklyOrSaleValue === value ? 'btn-step-selected' : ''}`}
                                        onClick={() => setWeeklyOrSaleValue(value)}
                                    >
                                        {value}
                                    </button>
                                ))}
                            </div>


                        </div>
                        <div className={`main-red-button mt-5 ${isContinueDisabled ? 'disabled' : ''}`}>
                            <a onClick={(e) => {
                                if (isContinueDisabled) {
                                    e.preventDefault(); // Prevents the default action if disabled
                                } else {
                                    setCurrentTab(1); // Moves to the next tab only if not disabled
                                    if (window.innerWidth <= 900) { // If screen width is 768px or smaller (mobile view)
                                        const container = document.getElementById('property-container');
                                        window.scrollTo({
                                            top: container.offsetTop, // Scroll to the top of the container
                                            behavior: 'smooth' // Smooth scrolling
                                        });
                                    }
                                }
                            }}>Continue</a>
                        </div>
                    </div>
                );

            case 1:  // Location Step
                return (
                    <div className="section location-step" >
                        <h3>Where’s your ideal location?</h3>
                        <p>
                            Share the map address of the property you're interested in!
                        </p>
                        <div className="address-input-group">
                            <label htmlFor="propertyAddress" className="address-label">Property address</label>
                            <input
                                type="text"
                                id="propertyAddress"
                                className="address-input"
                                placeholder="e.g. 123 Smith Street, Smithfield"
                                value={location}
                                onChange={handleInputChange}
                            />
                            {/* {suggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {suggestions.map((suggestion, index) => (
                          <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion.display_name}
                          </li>
                        ))}
                      </ul>
                    )} */}
                        </div>
                        <div className={`main-red-button mt-5 ${isContinueLocatioDisabled ? 'disabled' : ''}`}>
                            <a onClick={(e) => {
                                if (isContinueLocatioDisabled) {
                                    e.preventDefault(); // Prevents the default action if disabled
                                } else {
                                    setCurrentTab(2); // Moves to the next tab only if not disabled
                                }
                            }}>Continue</a>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="section-step4">
                        <div className="step4-your-needs">
                            <h3>When are you planning to buy your new home?</h3>
                            <div className="btn-group row">
                                <button onClick={() => setSelectedOption('Immediately')} className={`btn-step col-lg-3 ${selectedOption === 'Immediately' ? 'btn-step-selected' : ''}`}>Immediately</button>
                                <button onClick={() => setSelectedOption('1-3 months')} className={`btn-step col-lg-3 ${selectedOption === '1-3 months' ? 'btn-step-selected' : ''}`}>1-3 months</button>
                                <button onClick={() => setSelectedOption('4-6 months')} className={`btn-step col-lg-3 ${selectedOption === '4-6 months' ? 'btn-step-selected' : ''}`}>4-6 months</button>
                                <button onClick={() => setSelectedOption('6+ months')} className={`btn-step  col-lg-3 ${selectedOption === '6+ months' ? 'btn-step-selected' : ''}`}>6+ months</button>
                            </div>

                            <div>
                                <h3>What’s the purpose of your purchase?</h3>
                                <div className="btn-group row">
                                    <button onClick={() => setSelectedPurpose("Living")} className={`btn-step  col-lg-4 ${selectedPurpose === "Living" ? 'btn-step-selected' : ''}`}>Living</button>
                                    <button onClick={() => setSelectedPurpose("Investment")} className={`btn-step  col-lg-4 ${selectedPurpose === "Investment" ? 'btn-step-selected' : ''}`}>Investment</button>
                                    <button onClick={() => setSelectedPurpose("Vacation")} className={`btn-step  col-lg-4 ${selectedPurpose === "Vacation" ? 'btn-step-selected' : ''}`}>Vacation</button>
                                </div>

                                <div>
                                    <h3>What’s your preferred way to communicate?</h3>
                                    <div className="btn-group row">
                                        <button onClick={() => setSelectedUserCommunication("E-mail")} className={`btn-step col-lg-4 ${selectedUserCommunication === 'E-mail' ? 'btn-step-selected' : ''}`}>E-mail</button>
                                        <button onClick={() => setSelectedUserCommunication("Phone")} className={`btn-step col-lg-4 ${selectedUserCommunication === 'Phone' ? 'btn-step-selected' : ''}`}>Phone</button>
                                        <button onClick={() => setSelectedUserCommunication("In-app Messaging")} className={`btn-step col-lg-4 ${selectedUserCommunication === 'In-app Messaging' ? 'btn-step-selected' : ''}`}>In-app Messaging</button>

                                    </div>
                                </div>

                                <div>
                                    <h3>Are you pre-qualified or pre-approved for a mortgage?</h3>
                                    <div className="btn-group row">
                                        <button onClick={() => setIsLegalReady(true)} className={`btn-step col-lg-4 ${isLegalReady === true ? 'btn-step-selected' : ''}`}>Yes</button>
                                        <button onClick={() => setIsLegalReady(false)} className={`btn-step col-lg-4 ${isLegalReady === false ? 'btn-step-selected' : ''}`}>No</button>
                                    </div>

                                </div>
                                <div>
                                    <h3>Would you like assistance with negotiation and closing?</h3>
                                    <div className="btn-group row">
                                        <button onClick={() => setIsAssistance(true)} className={`btn-step col-lg-4 ${isAssistance === true ? 'btn-step-selected' : ''}`}>Yes</button>
                                        <button onClick={() => setIsAssistance(false)} className={`btn-step col-lg-4 ${isAssistance === false ? 'btn-step-selected' : ''}`}>No</button>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className={`main-red-button mt-5 ${isYourNeedsProvided ? 'disabled' : ''}`}>
                            <a onClick={(e) => {
                                if (isYourNeedsProvided) {
                                    e.preventDefault(); // Prevents the default action if disabled
                                } else {
                                    setCurrentTab(3); // Moves to the next tab only if not disabled
                                }
                            }}>Continue</a>
                        </div>
                    </div>

                );
            case 3:
                return (

                    <div className="section location-step">
                        <h3 className='mb-5'>Please share your email address and phone number.</h3>

                        <div className="address-input-group">
                            <label htmlFor="emailAddress" className="address-label">Email address</label>
                            <input
                                type="text"
                                id="emailAddress"
                                className="address-input"
                                placeholder="youremail@mail.com"
                                value={emailAdress}
                                onChange={handleEmailChange}
                            />

                        </div>
                        <br />
                        <div className="address-input-group">
                            <label htmlFor="phoneNumber" className="address-label">Phone Number</label>
                            <input
                                type="text"
                                id="phoneNumber"
                                className="address-input"
                                placeholder="+91 9123456780"
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                            />

                        </div>
                        {sendOtp && (
                            <div className="address-input-group">
                                <label htmlFor="otpNumber" className="address-label">Enter OTP sent to Email</label>
                                <input
                                    type="text"
                                    id="otpNumber"
                                    className="address-input"
                                    placeholder="Enter OTP"
                                    value={code}
                                    onChange={handleCodeChange}
                                />
                            </div>
                        )}

                        <div className={`main-red-button mt-5 ${isContactProvided ? 'disabled' : ''}`}>
                            <a onClick={(e) => {
                                if (isContactProvided) {
                                    e.preventDefault(); // Prevents the default action if disabled
                                } else {
                                    handleSubmit(e) // Moves to the next tab only if not disabled
                                }
                            }}>{sendOtp && verifyEmail
                                ? "Continue" // Show "Continue" if OTP is verified
                                : sendOtp
                                    ? "Verify OTP" // Show "Verify OTP" if OTP is sent but not verified
                                    : "Send OTP" // Show "Send OTP" if OTP is not sent
                                }</a>
                        </div>
                    </div>

                );

            default:
                return null;
        }
    };

    return (
        <div className="popular-categories">
            <div className="container">
                <div className="section-heading">
                    <ToastContainer />
                    <h2>Find your perfect agent match!</h2>
                    <h6>
                        Get all the details you need to compare agents side by side, so you're confident before your first conversation.
                    </h6>
                </div>
                <div className="naccs mb-5" id="property-container">
                    <div className="grid">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="menu">
                                    {tabs.map((tab, index) => (
                                        <div
                                            key={index}
                                            className={`thumb ${index === currentTab ? 'active' : ''}`}
                                            onClick={() => handleTabChange(index)}
                                        >
                                            <span className="icon">
                                                <img src={tab.image} alt={tab.title} />
                                            </span>
                                            {tab.title}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-9 align-self-center">
                                <ul className="nacc">
                                    <li className="active">{renderTabContent()}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepTabs;
