import React from 'react';
import '../OpportunityCard.css';

const OpportunityCard = ({ userDetails }) => {
    // Destructure data from userDetails object
    const {
        propertyType,
        bedroomCount,
        weeklyOrSaleValue,
        location,
        emailAddress,
        phoneNumber,
        selectedOption,
        selectedPurpose,
        selectedUserCommunication,
        isAssistance,
        isLegalReady,
    } = userDetails;

    return (
        <div className="opportunity-card">
            <h2>Opportunity Details</h2>
            <div className="opportunity-details">
                <p><strong>Property Type:</strong> {propertyType}</p>
                <p><strong>Bedroom Count:</strong> {bedroomCount}</p>
                <p><strong>Budget:</strong> {weeklyOrSaleValue}</p>
                <p><strong>Location:</strong> {location}</p>
                <p><strong>Email Address:</strong> {emailAddress}</p>
                <p><strong>Phone Number:</strong> {phoneNumber}</p>
                <p><strong>Moving Timeframe:</strong> {selectedOption}</p>
                <p><strong>Purpose:</strong> {selectedPurpose}</p>
                <p><strong>Preferred Communication:</strong> {selectedUserCommunication}</p>
                <p><strong>Mortgage Assistance:</strong> {isAssistance ? "Yes" : "No"}</p>
                <p><strong>Ready for Legal Work:</strong> {isLegalReady ? "Yes" : "No"}</p>
            </div>
        </div>
    );
};

export default OpportunityCard;
