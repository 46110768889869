import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import './responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home';
import RegisterPage from './Register';
import MultiStepForm from './AgentSignup';
import AgentProposalForm from './AgentProposal';
import Portal from './Portal';
import BuyerAgentsList from './SearchPage';
import ProfileDetails from './ProfileDetail';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/becomeAgent' element={<RegisterPage/>}/>
          <Route path='/register' element={<MultiStepForm/>}/>
          <Route path='/agentProposal' element={<AgentProposalForm/>}/>
          <Route path='/agentPortal' element={<Portal/>}/>
          <Route path='/find-buyer-agent' element={<BuyerAgentsList/>}/>
          <Route path='/buyer-agent-profile' element={<ProfileDetails/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
