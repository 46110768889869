import React, { useState, useEffect } from 'react';
import { FaArrowCircleLeft, FaExclamationCircle } from "react-icons/fa";
import Footer from './components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

let steps = 0;
const showToast = (message) => {
    toast.error(message, {
        icon: <FaExclamationCircle color="#f3f3f3" />,
        position: "top-right",
        autoClose: 3000, // Automatically close after 3 seconds
        hideProgressar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            fontSize: "18px",
            backgroundColor: "#FD0A51", // Custom background color
            color: "#ffffff", // Custom text color
            border: "1px solid #fff", // Border color
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Custom box-shadow
            borderRadius: "8px",
        },
    });
};


// const ProposalType = ({ onNext, onBack, formData, setFormData }) => {
//     const handleSelectProposal = (type) => {
//         setFormData({ ...formData, type });
//         onNext();
//     };

//     return (
//         <section className="registration">
//             {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}
//             <h1>Complete your Proposal</h1>
//             <p>Vendors and landlords request and compare agent proposals on LocalAgentFinder before choosing their agent. Select "BEGIN" below to complete your proposal.</p>
//             <p>If you chose to receive rental AND sales leads, remember to fill in your information for both. Use the rent and sell tabs at the top of the screen to choose which category you'd like to complete information for.</p>
//             <div className="registration-button">
//                 <button className='btn-step' onClick={() => handleSelectProposal('Sell')}>Sell</button>
//                 <button className='btn-step' onClick={() => handleSelectProposal('Rent')}>Rent</button>
//             </div>
//         </section>
//     );
// };

const StartStepPage = ({ onNext, onBack, formData, setFormData }) => {
    const handleSelectProposal = (type) => {
        // setFormData({ ...formData, type });
        onNext();
    };

    return (
        <section className="registration">
            {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}
            <h1>Finalize your profile</h1>
            <p>Homebuyers use FindMyAgent to request and compare profile from buyer's agents before making their decision.<br />Click 'BEGIN' below to submit your profile.</p>
            {/* <p>If you chose to receive rental AND sales leads, remember to fill in your information for both. Use the rent and sell tabs at the top of the screen to choose which category you'd like to complete information for.</p> */}
            <div className="registration-button">
                <button className='btn-step' onClick={() => handleSelectProposal('Buy')}>BEGIN</button>
                {/* <button className='btn-step' onClick={() => handleSelectProposal('Rent')}>Rent</button> */}
            </div>
        </section>
    );
};

const SalesMethod = ({ onNext, onBack, formData, setFormData }) => {
    const handleSalesMethod = (method) => {
        const updatedMethods = formData.salesMethod || [];
        // Check if the method is already selected
        if (updatedMethods.includes(method)) {
            // If it is selected, remove it
            setFormData({ ...formData, salesMethod: updatedMethods.filter(item => item !== method) });
        } else {
            // If it is not selected, add it
            setFormData({ ...formData, salesMethod: [...updatedMethods, method] });
        }
    };

    return (
        <section className="registration">
            <FaArrowCircleLeft className='back-icon' onClick={onBack} />
            <h1>What method of sales do you cover?</h1>
            <div className="registration-button">
                <button
                    className={`btn-step col-lg-3 ${formData.salesMethod && formData.salesMethod.includes('PRIVATE SALES') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSalesMethod('PRIVATE SALES')}
                >
                    PRIVATE SALES
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.salesMethod && formData.salesMethod.includes('AUCTION') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSalesMethod('AUCTION')}
                >
                    AUCTION
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.salesMethod && formData.salesMethod.includes('FIXED RATE') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSalesMethod('FIXED RATE')}
                >
                    FIXED RATE
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.salesMethod && formData.salesMethod.includes('OFF MARKET') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSalesMethod('OFF MARKET')}
                >
                    OFF MARKET
                </button>
            </div>
            <button className="btn-step" onClick={onNext}>Next</button>
        </section>
    );
};


const SaleAuthority = ({ onNext, onBack, formData, setFormData }) => {
    // Function to handle selection
    const handleSelectOption = (option) => {
        setFormData({ ...formData, saleAuthority: option });
        onNext()
    };

    return (
        <div className="registration duration-div">
            <FaArrowCircleLeft className='back-icon' onClick={onBack} />
            <h1>What's the duration of your buyer's agency agreement?</h1>
            <div className="row discribe-button">
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === 'NO EXCLUSIVITY' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('NO EXCLUSIVITY')}
                >
                    NO EXCLUSIVITY
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '1 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('1 MONTH')}
                >
                    1 MONTH
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '2 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('2 MONTH')}
                >
                    2 MONTH
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '3 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('3 MONTH')}
                >
                    3 MONTH
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '4 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('4 MONTH')}
                >
                    4 MONTH
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '5 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('5 MONTH')}
                >
                    5 MONTH
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '6 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('6 MONTH')}
                >
                    6 MONTH
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '9 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('9 MONTH')}
                >
                    9 MONTH
                </button>
                <button
                    className={`col-lg-4 col-sm-4 col-md-4 btn-step ${formData.saleAuthority === '12 MONTH' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('12 MONTH')}
                >
                    12 MONTH
                </button>
            </div>


        </div>
    );
};

const SalesTeamStep = ({ onNext, onBack, formData, setFormData }) => {
    // Local state to manage the sales team size input
    const [salesTeamSize, setSalesTeamSize] = useState(formData.salesTeamSize || '');

    // Handle input change
    const handleChange = (e) => {
        // setSalesTeamSize(e.target.value);
        setFormData({ ...formData, salesTeamSize: e.target.value });
    };

    // Handle the next button click with validation
    const handleNext = () => {
        if (!formData.salesTeamSize || formData.salesTeamSize.trim() === '') {
            showToast('Please enter the number of people in your sales team.');
            return;
        }
        onNext();
    };

    return (
        <div className="about-you">
            <h1>How many people are in your sales team?</h1>
            <div className="row">
                <form className=' col-lg-12 address-input-group'>
                    <div className="form-group-input">
                        <label htmlFor="numberInOffice" className="address-label">Your sales team count</label>
                        <input
                            id='numberInOffice'
                            type="number"
                            className="form-control"
                            placeholder="0"
                            value={formData.salesTeamSize}
                            onChange={handleChange}
                        />
                    </div>
                </form>
                <div className="button-group">
                    <button className="btn-step" onClick={onBack}>Back</button>
                    <button className="btn-step" onClick={handleNext}>Next</button>
                </div>
            </div>
        </div>
    );
};

const PropertyManagerStep = ({ onNext, onBack, formData, setFormData }) => {
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        if (value && isNaN(value)) {
            showToast('Please enter a valid number.');
        } else {

            setFormData({ ...formData, propertiesManaged: value });
        }
    };

    const handleNext = () => {
        if (!formData.propertiesManaged) {
            showToast('Please enter the number of properties.');
        } else {

            onNext();
        }
    };

    return (
        <div className="about-you">
            <h1>How many properties does each property manager look after?</h1>

            <form className=' col-lg-12 address-input-group'>
                <div className="form-group-input">
                    <label htmlFor="numberInOffice" className="address-label">Property count</label>
                    <input
                        id='numberInOffice'
                        type="text"
                        className="form-control"
                        placeholder="Enter number"
                        value={formData.propertiesManaged || ''}
                        onChange={handleChange}
                    />
                </div>
            </form>
            <div className="button-group">
                <button className="btn-step" onClick={onBack}>
                    Back
                </button>
                <button className="btn-step" onClick={handleNext}>
                    Next
                </button>
            </div>
        </div>
    );
};


const EmergencyContactStep = ({ onNext, onBack, formData, setFormData }) => {
    const [selectedOption, setSelectedOption] = useState(formData.emergencyContact || '');

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setFormData({ ...formData, emergencyContact: option });
        onNext();
    };


    return (
        <div className="registration">
            <h1>Do you offer a 24-hour emergency contact?</h1>

            <div className="registration-button">
                <button
                    className={`btn-step ${selectedOption === 'YES' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('YES')}
                >
                    YES
                </button>

                <button
                    className={`btn-step ${selectedOption === 'NO' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('NO')}
                >
                    NO
                </button>
            </div>


        </div>
    );
};

const InsuranceAdviceStep = ({ onNext, onBack, formData, setFormData }) => {
    const [selectedOption, setSelectedOption] = useState(formData.insuranceAdvice || '');

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setFormData({ ...formData, insuranceAdvice: option });
        onNext();
    };



    return (
        <div className="registration">
            <h1>Do you offer landlord insurance advice?</h1>

            <div className="registration-button">
                <button
                    className={`btn-step ${selectedOption === 'YES' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('YES')}
                >
                    YES
                </button>

                <button
                    className={`btn-step ${selectedOption === 'NO' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('NO')}
                >
                    NO
                </button>
            </div>

        </div>
    );
};

const PostcodeStep = ({ onNext, onBack, formData, setFormData }) => {
    const [postcodes, setPostcodes] = useState(formData.postcodes || ['', '', '', '', '']);

    // Handle postcode change
    const handlePostcodeChange = (index, value) => {
        const updatedPostcodes = [...postcodes];
        updatedPostcodes[index] = value;
        setPostcodes(updatedPostcodes);
        setFormData({ ...formData, postcodes: updatedPostcodes });
    };

    // Validate and move to next step
    const handleNext = () => {
        const filledPostcodes = postcodes.filter(pc => pc.trim() !== '');
        if (filledPostcodes.length === 0) {
            showToast('Please enter at least one postcode.');
            return;
        }
        onNext();
    };

    return (
        <div className="about-you postcode">
            <h1>Which postcodes do you cover?</h1>
            <p>You can nominate up to five postcodes. The FindMyAgent service allows for one agent per office per postcode.</p>

            <div className="postcode-inputs">
                {postcodes.map((postcode, index) => (
                    <input
                        key={index}
                        type="number"
                        className="postcode-input"
                        placeholder={`100${index + 1}`}
                        maxLength="4"
                        value={postcode}
                        onChange={(e) => handlePostcodeChange(index, e.target.value)}
                    />
                ))}
            </div>

            <div className='button-group'>
                <button className="btn-step" onClick={onBack}>Back</button>
                <button className="btn-step" onClick={handleNext}>Next</button>
            </div>
        </div>
    );
};

const ServiceRange = ({ onNext, onBack, formData, setFormData }) => {
    const handleServiceSelection = (service) => {
        const updatedServices = formData.services || [];
        // Toggle the service selection
        if (updatedServices.includes(service)) {
            // Remove the service if it's already selected
            setFormData({ ...formData, services: updatedServices.filter(item => item !== service) });
        } else {
            // Add the service if it's not selected
            setFormData({ ...formData, services: [...updatedServices, service] });
        }
    };

    const handleNext = () => {
        if (!formData.services || formData.services.length === 0) {
            showToast('Please select at least 1 service.');
            return;
        }
        onNext();
    };

    return (
        <section className="registration fullrange">
            <h1>Full Range of Services You Provide</h1>
            <div className="registration-button">
                <button
                    className={`btn-step col-lg-3 ${formData.services && formData.services.includes('Property Search') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleServiceSelection('Property Search')}
                >
                    Property Search
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.services && formData.services.includes('Negotiation') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleServiceSelection('Negotiation')}
                >
                    Negotiation
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.services && formData.services.includes('Closing Support') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleServiceSelection('Closing Support')}
                >
                    Closing Support
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.services && formData.services.includes('Market Analysis') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleServiceSelection('Market Analysis')}
                >
                    Market Analysis
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.services && formData.services.includes('Investment Consulting') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleServiceSelection('Investment Consulting')}
                >
                    Investment Consulting
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.services && formData.services.includes('Mortgage Guidance') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleServiceSelection('Mortgage Guidance')}
                >
                    Mortgage Guidance
                </button>
            </div>
            
            <div className="button-group">
                <button type="button" className="btn-step" onClick={onBack}>
                    Back
                </button>
                <button type="button" className="btn-step" onClick={handleNext}>
                    Next
                </button>
            </div>
        </section>
    );
};



const SpecializationAreas = ({ onNext, onBack, formData, setFormData }) => {
    const handleSpecialization = (specialization) => {
        const updatedSpecializations = formData.specializations || [];
        // Toggle the specialization selection
        if (updatedSpecializations.includes(specialization)) {
            // Remove the specialization if it's already selected
            setFormData({ ...formData, specializations: updatedSpecializations.filter(item => item !== specialization) });
        } else {
            // Add the specialization if it's not selected
            setFormData({ ...formData, specializations: [...updatedSpecializations, specialization] });
        }
    };
    const handleNext = () => {
        if (!formData.specializations || formData.specializations.length === 0) {
            showToast('Please select atleast 1 Area of specializations.');
            return;
        }
        onNext();
    };

    return (
        <section className="registration">
            {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}
            <h1>Areas of Specialization</h1>
            {/* <p>Select all areas that apply (e.g., residential, commercial, investment properties):</p> */}
            <div className="registration-button">
                <button
                    className={`btn-step col-lg-3 ${formData.specializations && formData.specializations.includes('Residential') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSpecialization('Residential')}
                >
                    Residential
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.specializations && formData.specializations.includes('Commercial') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSpecialization('Commercial')}
                >
                    Commercial
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.specializations && formData.specializations.includes('Investment Properties') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSpecialization('Investment Properties')}
                >
                    Investment Properties
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.specializations && formData.specializations.includes('Vacation Homes') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSpecialization('Vacation Homes')}
                >
                    Vacation Homes
                </button>
            </div>

            <div className="button-group">
                <button type="button" className="btn-step" onClick={onBack}>
                    Back
                </button>
                <button type="button" className="btn-step" onClick={handleNext}>
                    Next
                </button>
            </div>
        </section>
    );
};

const ClientTypes = ({ onNext, onBack, formData, setFormData }) => {
    const handleClientType = (clientType) => {
        const updatedClientTypes = formData.clientTypes || [];
        // Toggle the client type selection
        if (updatedClientTypes.includes(clientType)) {
            // Remove the client type if it's already selected
            setFormData({ ...formData, clientTypes: updatedClientTypes.filter(item => item !== clientType) });
        } else {
            // Add the client type if it's not selected
            setFormData({ ...formData, clientTypes: [...updatedClientTypes, clientType] });
        }
    };

    const handleNext = () => {
        if (!formData.clientTypes || formData.clientTypes.length === 0) {
            showToast('Please select at least 1 type of client.');
            return;
        }
        onNext();
    };

    return (
        <section className="registration typeofclients">
            <h1>Types of Clients You Typically Work With</h1>
            <div className="registration-button">
                <button
                    className={`btn-step col-lg-3 ${formData.clientTypes && formData.clientTypes.includes('First-time Buyers') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleClientType('First-time Buyers')}
                >
                    First-time Buyers
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.clientTypes && formData.clientTypes.includes('Experienced Buyers') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleClientType('Experienced Buyers')}
                >
                    Experienced Buyers
                </button>
                <button
                    className={`btn-step col-lg-3 ${formData.clientTypes && formData.clientTypes.includes('Investors') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleClientType('Investors')}
                >
                    Investors
                </button>
                
                <button
                    className={`btn-step col-lg-3 ${formData.clientTypes && formData.clientTypes.includes('Luxury Buyers') ? 'btn-step-selected' : ''}`}
                    onClick={() => handleClientType('Luxury Buyers')}
                >
                    Luxury Buyers
                </button>
                
            </div>

            <div className="button-group">
                <button type="button" className="btn-step" onClick={onBack}>
                    Back
                </button>
                <button type="button" className="btn-step" onClick={handleNext}>
                    Next
                </button>
            </div>
        </section>
    );
};


const BiographyStep = ({ onNext, onBack, formData, setFormData }) => {
    // Local state for validation errors

    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Validate inputs and move to next step
    const handleNext = () => {
        if (!formData.aboutYou || !formData.aboutAgency || !formData.negotiationApproach) {
            showToast('Please complete all fields before proceeding.');
            return;
        }
        onNext();
    };

    return (
        <div className="about-you biography">
            <h2>Your biography</h2>
            <p>Here's your opportunity to stand out and show homebuyers why you're the best choice. Once a buyer connects with you, you'll have the chance to provide tailored insights and support. Make sure to complete all three sections to proceed.</p>

            <form className=' col-lg-12 address-input-group'>
                {/* About You */}
                <div className="form-group-input">
                    <label htmlFor="aboutYou" className='address-label'>ABOUT YOU</label>
                    <textarea
                        type="text"
                        id="aboutYou"
                        name="aboutYou"
                        rows="3"
                        // placeholder="About you"
                        value={formData.aboutYou || ''}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>

                {/* About Your Agency */}
                <div className="form-group-input">
                    <label htmlFor="aboutAgency" className='address-label'>ABOUT YOUR AGENCY</label>
                    <textarea
                        type="text"
                        id="aboutAgency"
                        name="aboutAgency"
                        rows="3"
                        // placeholder="About your agency"
                        value={formData.aboutAgency || ''}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>

                {/* Marketing & Selling Approach */}
                <div className="form-group-input">
                    <label htmlFor="marketingApproach" className='address-label'>NEGOTIATION STYLE AND APPROACH</label>
                    <textarea
                        type="text"
                        id="marketingApproach"
                        name="negotiationApproach"
                        rows="3"
                        // placeholder="Marketing & selling approach"
                        value={formData.negotiationApproach || ''}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>

                {/* Validation Error */}
                {/* {error && <p className="error-message">{error}</p>} */}

                {/* Navigation Buttons */}
                <div className="button-group">
                    <button type="button" className="btn-step" onClick={onBack}>
                        Back
                    </button>
                    <button type="button" className="btn-step" onClick={handleNext}>
                        Next
                    </button>
                </div>
            </form>
        </div>
    );
};

const VideoCallStep = ({ onNext, onBack, formData, setFormData }) => {
    const [videoCallOption, setVideoCallOption] = useState(formData.videoCallOption || '');

    // Handle option selection
    const handleSelectOption = async (option) => {
        setVideoCallOption(option);
        await setFormData({ ...formData, videoCallOption: option });
        // if (!videoCallOption) {
        //     showToast('Please select an option before proceeding.');
        //     return;
        // }
        onNext();
    };



    return (
        <div className="registration videocall">
            <h1>Do you currently offer video calls with homebuyers who are looking to purchase a property?</h1>

            <div className="registration-button row">
                <button
                    className={`col-lg-12 col-sm-4 col-md-4 btn-step ${videoCallOption === 'YES' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('YES')}
                >
                    YES
                </button>
                <button
                    className={`col-lg-12 col-sm-4 col-md-4 btn-step ${videoCallOption === 'NO' ? 'btn-step-selected' : ''}`}
                    onClick={() => handleSelectOption('NO')}
                >
                    NO
                </button>
            </div>


        </div>
    );
};

const VideoCallTechnologyStep = ({ onNext, onBack, formData, setFormData }) => {
    // Available technologies
    const technologies = ['FACETIME', 'WHATSAPP', 'GOOGLE HANGOUTS', 'ZOOM', 'SKYPE', 'OTHER'];

    // State for selected technologies and other technology input
    const [selectedTech, setSelectedTech] = useState(formData.selectedTech || []);
    const [otherTech, setOtherTech] = useState(formData.otherTech || '');

    // Handle technology selection
    const handleSelectTechnology = (tech) => {
        if (tech === 'OTHER') {
            setSelectedTech((prev) =>
                prev.includes(tech) ? prev.filter((t) => t !== tech) : [...prev, tech]
            );
        } else {
            setSelectedTech((prev) =>
                prev.includes(tech) ? prev.filter((t) => t !== tech) : [...prev, tech]
            );
        }
        setFormData({ ...formData, selectedTech: [...selectedTech, tech], otherTech });
    };

    // Handle 'Next' click with validation
    const handleNext = () => {
        if (selectedTech.length === 0) {
            showToast('Please select at least one technology.');
            return;
        }
        onNext();
    };

    return (
        <div className="registration technology">
            <h1>What technology do or could you use to facilitate video calls?</h1>

            <div className="describe-buttons">
                {technologies.map((tech) => (
                    <button
                        key={tech}
                        className={`btn-step m-2 ${selectedTech.includes(tech) ? 'btn-step-selected' : ''}`}
                        onClick={() => handleSelectTechnology(tech)}
                    >
                        {tech}
                    </button>
                ))}
            </div>

            {/* Display input field if 'OTHER' is selected */}
            {selectedTech.includes('OTHER') && (
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="What Other Technology?"
                        value={otherTech}
                        onChange={(e) => setOtherTech(e.target.value)}
                        className="form-control"
                    />
                </div>
            )}

            <div className="button-group">
                <button className="btn-step" onClick={onBack}>
                    Back
                </button>
                <button className="btn-step" onClick={handleNext}>
                    Next
                </button>
            </div>
        </div>
    );
};

const DigitalSolutionsStep = ({ onNext, onBack, formData, setFormData }) => {
    // Available digital solutions
    const digitalSolutions = ['VIRTUAL APPRAISALS', 'VIRTUAL OPEN HOMES', '3D TOURS', 'ONLINE AUCTIONS', 'OTHER'];

    // State for selected solutions and other solution input
    const [selectedSolutions, setSelectedSolutions] = useState(formData.selectedSolutions || []);
    const [otherSolution, setOtherSolution] = useState(formData.otherSolution || '');

    // Handle solution selection
    const handleSelectSolution = (solution) => {
        if (solution === 'OTHER') {
            setSelectedSolutions((prev) =>
                prev.includes(solution) ? prev.filter((sol) => sol !== solution) : [...prev, solution]
            );
        } else {
            setSelectedSolutions((prev) =>
                prev.includes(solution) ? prev.filter((sol) => sol !== solution) : [...prev, solution]
            );
        }
        setFormData({ ...formData, selectedSolutions, otherSolution });
    };

    // Handle 'Next' click with validation
    const handleNext = () => {
        if (selectedSolutions.length === 0) {
            alert('Please select at least one solution.');
            return;
        }
        onNext();
    };

    return (
        <div className="registration digitalsolution">
            <h1>Which of the following digital solutions do you offer?</h1>

            <div className="describe-buttons">
                {digitalSolutions.map((solution) => (
                    <button
                        key={solution}
                        className={`btn-step m-2 ${selectedSolutions.includes(solution) ? 'btn-step-selected' : ''}`}
                        onClick={() => handleSelectSolution(solution)}
                    >
                        {solution}
                    </button>
                ))}
            </div>

            {/* Display input field if 'OTHER' is selected */}
            {selectedSolutions.includes('OTHER') && (
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="What Other Solution?"
                        value={otherSolution}
                        onChange={(e) => setOtherSolution(e.target.value)}
                        className="form-control"
                    />
                </div>
            )}

            <div className="button-group">
                <button className="btn-step" onClick={onBack}>
                    Back
                </button>
                <button className="btn-step" onClick={handleNext}>
                    Next
                </button>
            </div>
        </div>
    );
};


const FeeStructureStep = ({ onNext, onBack, formData, setFormData }) => {
    // Initial fee ranges with default values
    const initialRanges = [
        { low: '0', high: '300,000', chargeType: 'percentage', chargeValue: '5.000', marketingBudget: 'Free Marketing' },
    ];

    // Fee options
    const rangeOptions = ['50,000', '100,000', '200,000', '300,000', '500,000', '750,000', '1M', '1.5M', '2M'];
    const chargeOptions = ['percentage', 'fixed fee'];
    const marketingOptions = [
        'No Sale No Fee',
        'Free Marketing',
        'Less than $1,000',
        '$1,001 - $2,000',
        '$2,001 - $3,000',
        '$3,001 - $4,000',
        '$4,001 - $5,000',
        'More than $5,001',
    ];

    // State to handle multiple ranges
    const [feeRanges, setFeeRanges] = useState(formData.feeRanges || initialRanges);
    const [isError, setIsError] = useState(false);

    // Add a new higher range based on the previous range's high value
    const addHigherRange = () => {
        const lastHigh = feeRanges[feeRanges.length - 1].high;
        setFeeRanges([
            ...feeRanges,
            {
                low: lastHigh,
                high: '2M',
                chargeType: 'percentage',
                chargeValue: '2.000',
                marketingBudget: 'Free Marketing',
            },
        ]);
    };

    // Update range values
    const handleRangeChange = (index, field, value) => {
        const updatedRanges = [...feeRanges];
        updatedRanges[index][field] = value;

        // Check if the low and high values are the same
        if (field === 'high' && updatedRanges[index].low === value) {
            setIsError(true);
        } else {
            setIsError(false);
        }

        setFeeRanges(updatedRanges);
        setFormData({ ...formData, feeRanges: updatedRanges });
    };

    // Remove the last range
    const removeLastRange = () => {
        if (feeRanges.length > 1) {
            setFeeRanges(feeRanges.slice(0, -1));
        }
    };

    return (
        <div className="registration structurefee">
            <h1>How do you structure your fees?</h1>
            <p>
                After exchanging their information with you, homeowners will only ever see the fee relevant to their property.
                All fees include GST.
            </p>

            {feeRanges.map((range, index) => (
                <div key={index} className={`range-box ${isError && range.low === range.high ? 'error-box' : ''}`}>
                    <div className="range-row">
                          <div className='col-lg-6 col-sm-12 col-md-12'>
                          <label >FOR PROPERTIES BETWEEN</label>
                        <select
                            value={range.low}
                            onChange={(e) => handleRangeChange(index, 'low', e.target.value)}
                            disabled={index !== 0} // Disable lower dropdown for ranges other than the first
                        >
                            {rangeOptions.map((option) => (
                                <option key={option} value={option}>
                                    ${option}
                                </option>
                            ))}
                        </select>

                        <label>TO</label>
                        <select
                            value={range.high}
                            onChange={(e) => handleRangeChange(index, 'high', e.target.value)}
                            className={`${isError && range.low === range.high ? 'error-select' : ''}`}
                        >
                            {rangeOptions.map((option) => (
                                <option key={option} value={option}>
                                    ${option}
                                </option>
                            ))}
                        </select>
                          </div>

                       <div className='col-lg-6 col-sm-12 col-md-12'>
                       <label>I CHARGE A</label>
                        <select
                            value={range.chargeType}
                            onChange={(e) => handleRangeChange(index, 'chargeType', e.target.value)}
                        >
                            {chargeOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <input
                            type="number"
                            value={range.chargeValue}
                            placeholder={range.chargeType === 'fixed fee' ? 'e.g. 5000' : '5.000'}
                            onChange={(e) => handleRangeChange(index, 'chargeValue', e.target.value)}
                            className="charge-input"
                        />
                        {range.chargeType === 'percentage' ? <span>%</span> : <span>$</span>}
                       </div>

                    </div>
                </div>
            ))}

            {/* Add or Remove range */}
            <div className="range-buttons">
                <button className="btn-step" onClick={removeLastRange} disabled={feeRanges.length === 1}>
                    DELETE RANGE
                </button>
                <button className="btn-step" onClick={addHigherRange}>
                    ADD HIGHER RANGE
                </button>
            </div>

            {/* Preview Button */}
            <div className="button-group">
                <button className="btn-step" onClick={onBack}>
                    Back
                </button>
                <button className="btn-step" onClick={onNext} disabled={isError}>
                    Submit
                </button>
            </div>
        </div>
    );
};

const CongratsPage = ({ onNext, onBack, formData, setFormData }) => {
    const handleSelectRole = () => {
        // setFormData({ ...formData, role });
        onNext();
    };

    return (
        <section className="registration">
            {/* <FaArrowCircleLeft className='back-icon' onClick={onBack} /> */}
            <h1>Your {formData.type} profile has been created.</h1>
            <p>Just a reminder, your fees and other key information will not be revealed until after the homeowner has exchanged their details with you.</p>
            <div className="registration-button">
                <button className='btn-step' onClick={() => handleSelectRole()}>Go to Dasboard</button>
            </div>
        </section>
    );
};

const PropertyInspectionStep = ({ onNext, onBack, formData, setFormData }) => {
    const [selectedOption, setSelectedOption] = useState(formData.inspectionFrequency || '');

    const handleSelectOption = (option) => {
        setSelectedOption(option);
        setFormData({ ...formData, inspectionFrequency: option });
        onNext();
    };



    return (
        <div className="registration">
            <h1>How often do you typically run property inspections?</h1>

            <div className="button-group">
                {['AS REQUESTED', 'EVERY 3 MONTHS', 'EVERY 4 MONTHS', 'EVERY 6 MONTHS', 'EVERY 9 MONTHS', 'EVERY 12 MONTHS'].map(
                    (option) => (
                        <button
                            key={option}
                            className={`btn-step ${selectedOption === option ? 'btn-step-selected' : ''}`}
                            onClick={() => handleSelectOption(option)}
                        >
                            {option}
                        </button>
                    )
                )}
            </div>


        </div>
    );
};

const RentFeeStructureStep = ({ onNext, onBack, formData, setFormData }) => {
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <div className="registration">
            <h1>How do you structure your fees?</h1>
            <p>After exchanging their information with you, homeowners will only ever see the fee relevant to their property. All fees are plus GST.</p>

            <div className="range-box">
                {/* Letting Fee */}
                <div className='range-row'>
                    <div className="form-group-input">
                        <label htmlFor="lettingFee" className="address-label">Letting Fee</label>
                        <select
                            id="lettingFee"
                            name="lettingFee"
                            className="form-control"
                            value={formData.lettingFee || ''}
                            onChange={handleChange}
                        >
                            <option value="0 weeks">0 weeks</option>
                            <option value="1 week">1 week</option>
                            <option value="1.5 weeks">1.5 weeks</option>
                            <option value="2 weeks">2 weeks</option>
                            <option value="2.5 weeks">2.5 weeks</option>
                            <option value="3 weeks">3 weeks</option>
                            <option value="4 weeks">4 weeks</option>
                            <option value="5 weeks">5 weeks</option>
                        </select>
                    </div>

                    {/* Management Fee */}
                    <div className="form-group-input">
                        <label htmlFor="managementFee" className="address-label">Management Fee (%)</label>
                        <input
                            type="number"
                            id="managementFee"
                            name="managementFee"
                            className="form-control"
                            placeholder="e.g. 9.1"
                            value={formData.managementFee || ''}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Marketing Budget */}
                    <div className="form-group-input">
                        <label htmlFor="marketingBudget" className="address-label">Marketing Budget</label>
                        <select
                            id="marketingBudget"
                            name="marketingBudget"
                            className="form-control"
                            value={formData.marketingBudget || ''}
                            onChange={handleChange}
                        >
                            <option value="Free Marketing">Free Marketing</option>
                            <option value="No Lease No Fee">No Lease No Fee</option>
                            <option value="Less than $250">Less than $250</option>
                            <option value="$251 - $500">$251 - $500</option>
                            <option value="$501 - $750">$501 - $750</option>
                            <option value="$751 - $1,000">$751 - $1,000</option>
                            <option value="$1,001 - $1,500">$1,001 - $1,500</option>
                            <option value="$1,501 - $2,000">$1,501 - $2,000</option>
                            <option value="More than $2,000">More than $2,000</option>
                        </select>
                    </div>
                </div>

            </div>
            <div className="button-group">
                <button type="button" className="btn-step" onClick={onBack}>Back</button>
                <button type="button" className="btn-step" onClick={onNext}>Next</button>
            </div>
        </div>
    );
};


const AgentProposalForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({}); // State to store all form data
    const navigate = useNavigate();

    useEffect(() => {
        // Set token from localStorage into formData when component mounts or currentStep changes
        if (currentStep === 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                token: localStorage.getItem("authToken")
            }));
        }
    }, [currentStep]);

    const handleNext = () => {
        // if (currentStep < steps.length - 1) {

        // } else {
        //     handleSubmit();
        // }
        if(currentStep<13){
            setCurrentStep(currentStep + 1);
        }else{
            handleSubmit()
        }
        
        // if (currentStep === 6 && formData.videoCallOption === "NO") {
        //     setCurrentStep(currentStep + 2);
        // } else {
        //     setCurrentStep(currentStep + 1);
        // }

    };

    const handleBack = () => {
        if (currentStep > 0) {
            // if (currentStep === 8 && formData.videoCallOption === "NO") {
            //     setCurrentStep(currentStep - 2);
            // } else {
            //     setCurrentStep(currentStep - 1);
            // }
            setCurrentStep(currentStep - 1);
        }
    };

    // Example of submitting form data
    const handleSubmit = () => {
        navigate('/agentPortal');
      // Make API call here, using `formData`
      fetch('https://api.agentmatchr.com/api/proposal/create', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
      })
          .then((response) => response.json())
          .then((data) => {
              console.log('Success:', data);
            //   localStorage.setItem('authToken', data.data.token);
          })
          .catch((error) => {
              console.error('Error:', error);
          });
    };

    const renderSellStepContent = () => {
        switch (currentStep) {
            case 1:
                return <ServiceRange onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 2:
                return <SalesMethod onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 3:
                return <SaleAuthority onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 4:
                return <SalesTeamStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 5:
                return <PostcodeStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 6:
                return <SpecializationAreas onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 7:
                return <ClientTypes onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 8:
                return <BiographyStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 9:
                return <VideoCallStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 10:
                return <VideoCallTechnologyStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 11:
                return <DigitalSolutionsStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 12:
                return <FeeStructureStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 13:
                return <CongratsPage onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;

            default:
                return <SalesMethod onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
        }
    };

    const renderrentStepContent = () => {
        switch (currentStep) {
            case 1:
                return <SalesTeamStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 2:
                return <PropertyManagerStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 3:
                return <EmergencyContactStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 4:
                return <InsuranceAdviceStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 5:
                return <PropertyInspectionStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 6:
                return <PostcodeStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 7:
                return <BiographyStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 8:
                return <RentFeeStructureStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
            case 9:
                return <CongratsPage onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;

            default:
                return <SalesTeamStep onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
        }
    };

    const renderPage = () => {
        // Check if currentStep and previousStep are equal

        // console.log(localStorage.getItem("authToken") , "auth");

        if (currentStep === 0) {
            // setFormData({ ...formData, token: localStorage.getItem("authToken") });
            return <StartStepPage onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
        } else {
            return renderSellStepContent();
        }

        // Render different pages based on formData.type when steps are different
        // if (formData.type === 'Sell') {

        // } else if (formData.type === 'Rent') {
        //     return renderrentStepContent();
        // }

        // Default return if no conditions match (optional)
        return <StartStepPage onNext={handleNext} onBack={handleBack} formData={formData} setFormData={setFormData} />;
    };

    return (
        <div className="multi-step-form">
            <header className="header-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="main-nav">
                                <a href="/" className="logo">FindMyAgent</a>
{/* 
                                <button className="menu-trigger">
                                    <span>Menu</span>
                                </button> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <ToastContainer />

            {renderPage()}

            <Footer />
        </div>
    );
};

export default AgentProposalForm;