import React, { useState, useEffect } from 'react';
import Footer from './components/Footer';
import LoginModal from './components/LoginModel';
import { FaPlus , FaAlignJustify } from 'react-icons/fa'; // Assuming you are using FontAwesome icons


const RegisterPage = () => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu toggle

    // Function to open the modal
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > window.innerHeight - 400) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
    return (
        <div>
            {/* Register Section */}
            <header className={`header-area ${isScrolled ? 'header-scrolled' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="main-nav">
                                <a href="/" className="logo">FindMyAgent</a>
                                <ul className="nav">
                                    <li>
                                        <div className="main-white-button-header">
                                            <a onClick={handleOpenModal}> Login</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="main-white-button-header">
                                            <a href="/register"> Register</a>
                                        </div>
                                    </li>
                                </ul>
                                <div className="menu-trigger " onClick={toggleMenu}>
                               <FaAlignJustify className="menu-trigger" />
                           {isMenuOpen && (
                             <ul className="nav mobile-menu"> {/* Mobile only */}
                              <li>
                              <a href="/becomeAgent">Become an Agent</a>
                              </li>
                               </ul>
                                  )}
                               </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <section className="register">
                <div className="center_card">
                    <h1>Win more listings.<br />Grow your business</h1>
                    <div className="main-white-button-header">
                        <a href="/register" > Register Now</a>
                    </div>
                </div>
            </section>

            {/* Supporting Agent Growth Section */}
            <section className="supporting-sec">
                <h3>Supporting agent growth</h3>
                <div className="row">
                    <div className="sup-card col-sm-12 col-md-4 col-lg-4 card">
                        <img src="/images/team_lead.jpg" alt="Highly qualified leads" />
                        <div className="card-body">
                            <h5 className="card-title">Highly qualified leads</h5>
                            <p>We connect thousands of homeowners with real estate agents across Australia every month.</p>
                        </div>
                    </div>
                    <div className="sup-card col-sm-12 col-md-4 col-lg-4 card">
                        <img src="/images/scott-graham-5fNmWej4tAA-unsplash.jpg" alt="Lead management technology" />
                        <div className="card-body">
                            <h5 className="card-title">Lead management technology</h5>
                            <p>Manage and update your leads within your agent app or portal, where you can also delegate leads to other team members.</p>
                        </div>
                    </div>
                    <div className="sup-card col-sm-12 col-md-4 col-lg-4 card">
                        <img src="/images/zane-bolen-mzDQf7SVkUk-unsplash.jpg" alt="National account management" />
                        <div className="card-body">
                            <h5 className="card-title">National account management program</h5>
                            <p>We’re here to support you in winning more listings by keeping you up-to-date with insights and introducing you to new tools and features.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* How It Works Section */}
            <section className="working-sec">
                <h3>How It Works</h3>
                <div className="row">
                    <div className="work-card col-sm-12 col-md-12 col-lg-4 card">
                        <img src="/images/create-personal.jpg" alt="Create your proposal" />
                        <div className="card-body">
                            <h5 className="card-title">1. Create your proposal</h5>
                            <p>Add some information about yourself, your agency and your fees which will be presented to homeowners comparing agents side-by-side.</p>
                        </div>
                    </div>
                    <div className="work-card col-sm-12 col-md-12 col-lg-4 card">
                        <img src="/images/receving-message.jpg" alt="Receive qualified leads" />
                        <div className="card-body">
                            <h5 className="card-title">2. Receive qualified leads</h5>
                            <p>You’ll receive instant notifications to your app or portal where you can easily respond to, manage, and nurture your leads.</p>
                        </div>
                    </div>
                    <div className="work-card col-sm-12 col-md-12 col-lg-4 card">
                        <img src="/images/py-on-success.jpg" alt="Pay-on-success" />
                        <div className="card-body">
                            <h5 className="card-title">3. Pay-on-success</h5>
                            <p>You only pay LocalAgentFinder a fee if you successfully sell or manage the property.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className='w-100 h-100'>
            {isModalOpen && <LoginModal onClose={handleCloseModal} />}
            </div>
            
            <Footer/>
        </div>
    );
};

export default RegisterPage;
