import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaSearch } from "react-icons/fa";
import StepTabs from './components/RealEstateSignup';
import Header from './components/Header';
import Footer from './components/Footer';


const Home = () => {
    const [area, setArea] = useState("All Areas");
    const navigate = useNavigate();
    localStorage.removeItem("userDetails")

    const handleSearch = (e) => {
        e.preventDefault();
        // Perform search logic or navigate to the results page
        navigate("/find-buyer-agent");
    };

    return (
        <div>
            {/* Preloader */}
            {/* <div id="js-preloader" className="js-preloader">
        <div className="preloader-inner">
          <span className="dot"></span>
          <div className="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div> */}

            {/* Header */}
            <Header height={1}/>

            {/* Main Banner */}
            <div className="main-banner">
                <div className='container1'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="top-text header-text">
                                <h2>Connecting You with Expert Buyer’s Agents Across the UK</h2>
                                <h6>Your one-stop platform for finding professional agents to secure your dream home.</h6>
                            </div>
                        </div>
                        <div className="col-lg-12 form-search-container">
                            <form id="search-form" onSubmit={handleSearch}>
                                <div className="row">
                                    <div className="col-lg-8 align-self-center">
                                        <fieldset>
                                            <select
                                                name="area"
                                                className="form-select"
                                                value={area}
                                                onChange={(e) => setArea(e.target.value)}
                                            >
                                                <option value="All Areas">All Areas</option>
                                                <option value="New Village">New Village</option>
                                                <option value="Old Town">Old Town</option>
                                                <option value="Modern City">Modern City</option>
                                            </select>
                                        </fieldset>
                                    </div>
                                    <div className="col-lg-4">
                                        <fieldset>
                                            <button className="main-button" type="submit">Search
                                            </button>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
                
            </div>
            <section className="choose-agent ">
                <div className="container">
                    <h1>Fast, Free and Simple</h1>
                    <p>You'll have everything you need to find the right agent, allowing you to compare their information side by side before having the first conversation.</p>
                    <div className="steps row">
                        <div className="step col-lg-4 col-md-4 col-sm-12">
                            <img src={require("./images/magnifying-glass-solid.svg").default} alt="Search Icon" />
                            <h5>Search</h5>
                            <p>Enter a few details about your property search. It’s easy, and only takes a couple of minutes.</p>
                        </div>
                        <div className="step col-lg-4 col-md-4 col-sm-12">
                            <img src={require("./images/people-group-solid.svg").default} alt="Compare Icon" />
                            <h5>Compare</h5>
                            <p>Compare your local agents side-by-side. Comfortably compare real estate agent fees and service information online.</p>
                        </div>
                        <div className="step col-lg-4 col-md-4 col-sm-12">
                            <img src={require("./images/link-solid.svg").default} alt="Connect Icon" />
                            <h5>Connect</h5>
                            <p>You decide which agents to connect with. Contact the agents you like, and pass on the ones you don’t.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Real People, Real Service Section */}
            <section className="real-people">
                <div className="container">
                    <h2>Real People, Real Service</h2>
                    <div className="services row">
                        <div className="service col-lg-4 col-md-4 col-sm-12">
                            <img src={require("./images/public-service.png")} alt="Customer Care" />
                            <h5 className='mt-3 mb-4'>Tailored Agent Search</h5>
                            <p>Find expert buyer’s agents specialized in your needs and location.</p>
                        </div>
                        <div className="service col-lg-4 col-md-4 col-sm-12">
                            <img src={require("./images/negotiation.png")} alt="Agent Neutral" />
                            <h5 className='mt-3 mb-4'>Negotiation & Closing Support</h5>
                            <p>Get expert guidance to secure the best deal and a smooth closing.</p>
                        </div>
                        <div className="service col-lg-4 col-md-4 col-sm-12">
                            <img src={require("./images/real-estate.png")} alt="Free Service" />
                            <h5 className='mt-3 mb-4'>Market Insights & Advice</h5>
                            <p>Receive up-to-date market trends and advice from agents to make informed decisions</p>
                        </div>
                    </div>
                </div>
            </section>

            <StepTabs />

            {/* Footer */}
            <Footer/>
        </div>
    );
};

export default Home;
