import React, { useState, useEffect } from 'react';
// import './Portal.css'; // Make sure to have the corresponding CSS
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import OpportunityCard from './components/OppertunityCard';


const Portal = () => {
    const [userName] = useState("Agent Name"); // Placeholder for the user name
    const [newOpportunities] = useState(0); // Example state for opportunities count
    const [oppertunityData, setOppertunityData] = useState('')
    const [activeTab, setActiveTab] = useState('Dashboard');
    const [agentDetails, setAgentDetails] = useState('');
    const [agentProposal, setAgentProposal] = useState([]);
    const [postcodes, setPostcodes] = useState([]);

    const navigate = useNavigate();

     // const data = {
        //     "_id": "672876a376bc1f02ebfc237a",
        //     "agencyDescription": "CORPORATE",
        //     "agencyName": "GreenValley",
        //     "branch": "Manchester",
        //     "email": "balak.590@gmail.com",
        //     "firstName": "bala",
        //     "lastName": "kumar",
        //     "officeAddress": "Manchester",
        //     "password": "$2a$10$UztF1ZyM.giHnRyniUFD1urgygJbqaSvHMJpN1fUnnx0tCNlE1eoG",
        //     "phone": "09003399086",
        //     "role": [
        //         "SALES MANAGER"
        //     ],
        //     "imageUrl": "",
        //     "agencyImageUrl": "",
        //     "proposals": [
        //         {
        //             "aboutAgency": "From understanding the nuances of property types to guiding you through competitive bidding, this Buyer Agent is backed by a reputable agency dedicated to excellence. With a strong grasp of market trends and an eye for value, the agent's profile reflects years of experience and an unwavering commitment to client satisfaction.",
        //             "aboutYou": "Dive into the world of real estate with a trusted ally by your side – the Buyer Agent! This profile is more than just a list of credentials; it's a gateway to a dynamic, knowledgeable, and client-focused experience. Our Buyer Agent profile is crafted with you in mind, showcasing an agent who is not only skilled in navigating the market but is genuinely passionate about helping you find the perfect property.",
        //             "clientTypes": [
        //                 "Experienced Buyers",
        //                 "Investors"
        //             ],
        //             "negotiationApproach": "Whether you’re a first-time buyer, a seasoned investor, or someone seeking the perfect home to settle down in, this agent knows how to tailor their approach to suit your needs. They are here to offer insights, negotiate deals, and provide expert advice every step of the way.",
        //             "otherSolution": "",
        //             "otherTech": "",
        //             "postcodes": [
        //                 "1000",
        //                 "2000",
        //                 "3000",
        //                 "",
        //                 ""
        //             ],
        //             "saleAuthority": "12 MONTH",
        //             "salesMethod": [
        //                 "PRIVATE SALES",
        //                 "AUCTION"
        //             ],
        //             "salesTeamSize": "10",
        //             "selectedSolutions": [
        //                 "VIRTUAL OPEN HOMES"
        //             ],
        //             "selectedTech": [
        //                 "GOOGLE HANGOUTS"
        //             ],
        //             "services": [
        //                 "Negotiation",
        //                 "Property Search",
        //                 "Closing Support",
        //                 "Mortgage Guidance"
        //             ],
        //             "specializations": [
        //                 "Residential",
        //                 "Commercial",
        //                 "Investment Properties"
        //             ],
        //             "videoCallOption": "NO",
        //             "_id": "6728778276bc1f02ebfc237e",
        //             "createdAt": "2024-11-04T07:28:02.098Z",
        //             "updatedAt": "2024-11-04T07:28:02.098Z"
        //         },
        //         {
        //             "aboutAgency": "From understanding the nuances of property types to guiding you through competitive bidding, this Buyer Agent is backed by a reputable agency dedicated to excellence. With a strong grasp of market trends and an eye for value, the agent's profile reflects years of experience and an unwavering commitment to client satisfaction.",
        //             "aboutYou": "Dive into the world of real estate with a trusted ally by your side – the Buyer Agent! This profile is more than just a list of credentials; it's a gateway to a dynamic, knowledgeable, and client-focused experience. Our Buyer Agent profile is crafted with you in mind, showcasing an agent who is not only skilled in navigating the market but is genuinely passionate about helping you find the perfect property.",
        //             "clientTypes": [
        //                 "Experienced Buyers",
        //                 "Investors"
        //             ],
        //             "negotiationApproach": "Whether you’re a first-time buyer, a seasoned investor, or someone seeking the perfect home to settle down in, this agent knows how to tailor their approach to suit your needs. They are here to offer insights, negotiate deals, and provide expert advice every step of the way.",
        //             "otherSolution": "",
        //             "otherTech": "",
        //             "postcodes": [
        //                 "1000",
        //                 "2000",
        //                 "3000",
        //                 "",
        //                 ""
        //             ],
        //             "saleAuthority": "12 MONTH",
        //             "salesMethod": [
        //                 "PRIVATE SALES",
        //                 "AUCTION",
        //                 "FIXED RATE"
        //             ],
        //             "salesTeamSize": "10",
        //             "selectedSolutions": [
        //                 "3D TOURS"
        //             ],
        //             "selectedTech": [
        //                 "GOOGLE HANGOUTS"
        //             ],
        //             "services": [
        //                 "Property Search",
        //                 "Negotiation",
        //                 "Closing Support",
        //                 "Market Analysis"
        //             ],
        //             "specializations": [
        //                 "Residential",
        //                 "Commercial",
        //                 "Investment Properties"
        //             ],
        //             "videoCallOption": "YES",
        //             "_id": "672886dcedfcaa5c07ff18c8",
        //             "createdAt": "2024-11-04T08:33:32.888Z",
        //             "updatedAt": "2024-11-04T08:33:32.888Z"
        //         }
        //     ],
        //     "createdAt": "2024-11-04T07:24:19.099Z",
        //     "updatedAt": "2024-11-04T08:33:32.889Z",
        //     "__v": 2
        // }

        // setAgentDetails(data);
        // setAgentProposal(data?.proposals || [])
        // setPostcodes(data?.proposals[0]?.postcodes || [])

        // const oData = [
        //     {
        //         "_id": "67288f881846ec711a7129e7",
        //         "bedroomCount": "1",
        //         "emailAddress": "balak.5690@gmail.com",
        //         "isAssistance": true,
        //         "isLegalReady": true,
        //         "location": "Che",
        //         "phoneNumber": "1234567890",
        //         "propertyType": "House",
        //         "selectedOption": "Immediately",
        //         "selectedPurpose": "Living",
        //         "selectedUserCommunication": "E-mail",
        //         "weeklyOrSaleValue": "Less than $200k",
        //         "buyerAgentId": "672876a376bc1f02ebfc237a",
        //         "isAccepted": false,
        //         "createdAt": "2024-11-04T09:10:32.936Z",
        //         "updatedAt": "2024-11-04T09:10:32.936Z",
        //         "__v": 0
        //     },
        //     {
        //         "_id": "67288f9e1846ec711a7129ea",
        //         "bedroomCount": "1",
        //         "emailAddress": "balak.560@gmail.com",
        //         "isAssistance": true,
        //         "isLegalReady": true,
        //         "location": "Che",
        //         "phoneNumber": "1234567890",
        //         "propertyType": "House",
        //         "selectedOption": "Immediately",
        //         "selectedPurpose": "Living",
        //         "selectedUserCommunication": "E-mail",
        //         "weeklyOrSaleValue": "Less than $200k",
        //         "buyerAgentId": "672876a376bc1f02ebfc237a",
        //         "isAccepted": false,
        //         "createdAt": "2024-11-04T09:10:54.278Z",
        //         "updatedAt": "2024-11-04T09:10:54.278Z",
        //         "__v": 0
        //     }
        // ]

        // setOppertunityData(oData)

    useEffect(() => {
        const token = localStorage.getItem("authToken");

        const loginData = {
            token: token,
        };
        if (!token) {
            navigate('/becomeAgent');
            return;
        }
       

        axios
            .post('https://api.agentmatchr.com/api/user/info', loginData)
            .then((response) => {
                setAgentDetails(response.data.data);
                setAgentProposal(response.data.data?.proposals || [])
                setPostcodes(response.data.data?.proposals[0]?.postcodes || [])
            })
            .catch((err) => {
                // Navigate to /becomeAgent if fetching fails
                navigate('/becomeAgent');
            });

        axios
            .post('https://api.agentmatchr.com/api/buyerAgent/requests', loginData)
            .then((response) => {
                setOppertunityData(response.data.data);
                // setAgentProposal(response.data.data?.proposals || [])
                // setPostcodes(response.data.data?.proposals?.postcodes || [])
            })
            .catch((err) => {
                // Navigate to /becomeAgent if fetching fails
                // navigate('/becomeAgent');
            });

        // }, []);
    }, [navigate]);


    const renderContent = () => {
        switch (activeTab) {
            case 'Dashboard':
                return <Dashboard />;
            case 'Opportunities':
                return <Opportunities />;
            case 'Profile':
                return <Profile />;
            case 'Reviews':
                return <Reviews />;
            default:
                return <Dashboard />;
        }
    };

    const Dashboard = () => (
        <div>
            <main className="portal-main">
                <section className="opportunity-section">
                    <h2>New Opportunities</h2>
                    {oppertunityData.length === 0 ? (
                        <p>You have no new opportunities to contact.</p>
                    ) : (
                        <p>You have {oppertunityData.length} new opportunities.</p>
                    )}
                </section>

                <section className="actions-section">
                    <h2>Actions</h2>
                    <div className="actions-grid">
                        <div className="action-box">
                            <h3>Welcome to your portal</h3>
                            <p>Your portal allows you to track your performance and build your brand with ease! Take advantage of brand new features and gain more insight into how homeowners think.</p>
                            <button className="call-button">Call LocalAgentFinder</button>
                        </div>

                        <div className="action-box">
                            <h3>Agent Team</h3>
                            <p>Contact us</p>
                            <div className="contact-details">
                                <p>📞 1300 765 969</p>
                                <p>📧 agents@localagentfinder.com.au</p>
                            </div>
                        </div>

                        <div className="action-box">
                            <h3>Offer Digital Solutions to Help You Sell or Rent Property?</h3>
                            <p>Let us know if you use digital solutions like virtual appraisals or if you're interested in learning more.</p>
                            <button className="update-profile-button">Update Profile</button>
                        </div>
                    </div>
                </section>

                <section className="sales-performance-section">
                    <h2>Your Sales Performance</h2>
                    {/* Add sales performance details here */}
                </section>
            </main>
        </div>
    );

    const Opportunities = () => (
        <div>
            <h2>Opportunities</h2>
            <p>Here you can view your opportunities.</p>
            <div className="opportunity-list">
                {oppertunityData.map((opportunity) => (
                    <OpportunityCard key={opportunity._id} userDetails={opportunity} />
                ))}
            </div>
        </div>
    );

    const Profile = () => {
        const [videoLink, setVideoLink] = useState('');
        const [startDate, setStartDate] = useState('');
        const [endDate, setEndDate] = useState('');

        const handleSaveVideoLink = () => {
            // Save the video link logic here
            console.log('Video Link Saved:', videoLink);
        };

        const handleSubmitLeave = () => {
            // Submit leave dates logic here
            console.log('Leave Dates Submitted:', { startDate, endDate });
        };

        return (
            <div className="profile-page">
                <h1>Edit Profile</h1>

                <div className="profile-section">
                    {/* Profile Section */}
                    <div className="profile-card">
                        <h2>Your Profile</h2>
                        <p>Edit your image and company logo. To update other profile details, please call our agent team.</p>
                        <div className="profile-pic-section">
                            <div className="profile-pic">
                                <p>Edit photo</p>
                            </div>
                            <div className="company-logo">
                                <p>Edit logo</p>
                            </div>
                        </div>
                        <div className="profile-info">
                            <h3>{agentDetails.firstName} {agentDetails.lastName}</h3>
                            <p>{agentDetails.role[0]}</p>
                            <h5>{agentDetails.agencyName}</h5>
                            <p>{agentDetails.agencyDescription}</p>
                            <p>📞 {agentDetails.phone}</p>
                            <p>✉️ {agentDetails.email}</p>
                        </div>
                    </div>

                    {/* Proposal Section */}
                    <div className="proposal-card">
                        {/* <h2>Your Proposal Preview</h2>
                        <p>View and edit your proposal (what homeowners see during comparison).</p> */}
                        {agentProposal.length === 0 ? (
                            <button
                                className="btn-complete-proposal"
                                onClick={() => navigate("/agentProposal")}
                            >
                                Complete Profile
                            </button>
                        ) : (
                            <div className="proposal-card-inside">
                                <h2>Your Profile Preview</h2>
                                <p>View and edit your profile (what homeowners see during comparison).</p>
                                {/* Additional content based on proposal data can go here */}
                                <button
                                    className="btn-complete-proposal"
                                    onClick={() => navigate('/buyer-agent-profile', { state: { buyerAgent: agentDetails } })}

                                >
                                    View Profile
                                </button>
                                <button
                                    className="btn-complete-proposal"
                                    onClick={() => navigate("/agentProposal")}
                                >
                                    Edit Profile
                                </button>
                            </div>
                        )}
                    </div>

                    {/* Video Link Section */}
                    <div className="video-link-card">
                        <h2>Your Video Link</h2>
                        <p>Give homeowners an insight into who you are or an example of you in action at an auction!</p>
                        <input
                            type="text"
                            placeholder="Add YouTube video link"
                            value={videoLink}
                            onChange={(e) => setVideoLink(e.target.value)}
                        />
                        <button className="btn-save" onClick={handleSaveVideoLink}>Save</button>
                    </div>
                </div>

                {/* Postcodes Section */}
                <div className="postcodes-card">
                    <h2>Postcodes You Service</h2>
                    <p>To update your postcodes, please call the agent team on 1300 765 969</p>
                    <div className="postcodes-list">
                        {/* <span>2000</span> <span>2001</span> <span>2002</span> <span>2003</span>
                        <span>2004</span> <span>2005</span> */}
                        {postcodes.length > 0 ? (
                            postcodes.map((postcode, index) => (
                                postcode ? <span key={index}>{postcode}</span> : null
                            ))
                        ) : (
                            <div>No postcodes available</div>
                        )}
                    </div>

                </div>

                {/* Going Away Section */}
                <div className="leave-section">
                    <h2>Going Away?</h2>
                    <p>Let us know about any upcoming leave so we can let you get on with your break.</p>
                    <input
                        type="date"
                        placeholder="Start Date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        placeholder="End Date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <button className="btn-submit" onClick={handleSubmitLeave}>Submit</button>
                </div>
            </div>
        );
    };

    const Reviews = () => (
        <div>
            <h2>Reviews</h2>
            <p>See your reviews and feedback.</p>
        </div>
    );
    return (
        <div className="portal-container">
            {/* Header */}
            <header className="portal-header">
                <a href="/" className="logo">FindMyAgent</a>
                <div className="contact-info">
                    <span className="phone-icon">📞</span>
                    <span>1300 765 969</span>
                    <span>Call Agent Team</span>
                </div>
                <div className="user-icon">
                    {/* <img src="user-profile-icon.png" alt="Profile" /> */}
                    <FaUserCircle />
                </div>
            </header>

            {/* Navigation Bar */}
            <nav className="portal-navbar">
                <ul>
                    <li className={activeTab === 'Dashboard' ? 'active' : ''} onClick={() => setActiveTab('Dashboard')}>Dashboard</li>
                    <li className={activeTab === 'Opportunities' ? 'active' : ''} onClick={() => setActiveTab('Opportunities')}>Opportunities</li>
                    <li className={activeTab === 'Profile' ? 'active' : ''} onClick={() => setActiveTab('Profile')}>Profile</li>
                    <li className={activeTab === 'Reviews' ? 'active' : ''} onClick={() => setActiveTab('Reviews')}>Reviews</li>
                </ul>
            </nav>

            {/* Main Content */}
            <div className="portal-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default Portal;
